import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc, setDoc,doc, serverTimestamp  } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// TODO remove, this demo shouldn't need to reset the theme.
export default function NewImeiPage() {

  const [country, setCountry] = React.useState('srilanka');
  
  const [imeiError, setImeiError] = React.useState(false);
  const [serialnoError, setSerialnoError] = React.useState(false);
  const [productError, setProductError] = React.useState(false);
  const [date, setDate] = React.useState(dayjs());
  const [loading, setLoading] = React.useState(true);

  const [productList, setProductList] = React.useState([]);
  const [product, setProduct] = React.useState(null);

  const navigate = useNavigate();

  React.useEffect(() => {

    let isMounted = true;
    
    const doFetch = async () => {
      const querySnapshot = await getDocs(collection(db, "product"));
      const arr = [];
      querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id,
        });
        if (isMounted) {
          setProductList(arr);
          setLoading(false);
        }
      });
    };

    doFetch() // start the async work
      .catch((err) => {
        if (!isMounted) return; // unmounted, ignore.
        // TODO: Handle errors in your component
        console.error("failed to fetch data", err);
      });


    return () => {
      isMounted = false;
    };
    
  }, [])

  const handleChangeProduct = (event) => {
    setProduct(event.target.value);
  };

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let imeiPass = false;
    let serialnoPass = false;
    let productPass = false;

    const data = new FormData(event.currentTarget);

    if(data.get('serialno').length > 0){
      setSerialnoError(false)
      serialnoPass = true;
    }
    else
    {
      setSerialnoError(true)
    }

    if(data.get('imei').length > 7){
      setImeiError(false)
      imeiPass = true;
    }
    else
    {
      setImeiError(true)
    }

    if(product){
      setProductError(false)
      productPass = true;
    }
    else
    {
      setProductError(true)
    }

    if(imeiPass === true && productPass === true && serialnoPass === true)
    {

        const currentIndex = productList.map(val=>val.id).indexOf(product)
        
        setLoading(true);
        await setDoc(doc(db, "imei", `${ data.get('imei').replace('/','-').replace(/\s/g,'')}`), {
          product_name: currentIndex !== -1 ? productList[currentIndex].name : null,
          product_id: product,
          partno: currentIndex !== -1 ? productList[currentIndex].partno : null,
          country: country || null,
          shipmentdate: date.$d.toISOString().substring(0, 10) || null,
          imeino: data.get('imei') || null,
          serialno: data.get('serialno') || null,
          created: serverTimestamp(),
        }).then(()=>{
          setLoading(false);
          navigate('/dashboard/imei')
        }).catch(()=>{
          setLoading(false);
        });

        

    }


  };

  if(loading){
    return (
      
      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column"  }}>
        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
        flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <HorizontalSplitIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            New IMEI
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  name="serialno"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  required
                  error={serialnoError}
                  fullWidth
                  id="serialno"
                  label="Serial No"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  name="imei"
                  type='number'
                  required
                  error={imeiError}
                  fullWidth
                  id="imei"
                  label="IMEI"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Country"
                    onChange={handleChangeCountry}
                  >
                    <MenuItem value={"srilanka"}>Sri Lanka</MenuItem>
                    <MenuItem value={"myanmar"}>Myanmar</MenuItem>
                    <MenuItem value={"brunei"}>Brunei</MenuItem>
                    <MenuItem value={"nepal"}>Nepal</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl error={productError}  style={{ margin: 0, width: "100%" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">Product</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Product"
                    value={product}
                    onChange={handleChangeProduct}
                  >
                    {productList.map(val=>{
                      return(
                        <MenuItem style={{ fontSize: 12}} value={val.id}>{` ${val.partno} - ${val.name} `}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <LocalizationProvider sx={{ width: "100%"}}  dateAdapter={AdapterDayjs}>
                  <DatePicker 
                   label="Shipment Date"
                   value={date}
                   onChange={(newDate) => setDate(newDate)}
                   sx={{ width: "100%"}}  />
                </LocalizationProvider>
              </Grid>

              
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add Product
            </Button>

          </Box>
        </Box>

      </Container>
  );
}