import React, { useEffect }  from "react";
import { Paper, 
  Typography, 
  Grid, 
  Button, 
  TextField,
  FormControl,
  Select,
  MenuItem,
  Divider,
  CircularProgress
 } from '@mui/material';
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";
import { initializeApp } from "firebase/app";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import moment from "moment";

import SC from './SC';
import SCRepairDetail from './SCRepairDetail';
// import ScreenCrack from './ScreenCrack/ScreenCrack';

const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const rtdb = getDatabase(app);


function SignIn(props) {

  const [IMEINo, setIMEINo] = React.useState("");
  const [resubmitId, setResubmitId] = React.useState(null);
  const [isIMEINo, setIsIMEINo] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [product, setProduct] = React.useState(null);
  const [claimButtonDisable, setClaimButtonDisable] = React.useState(true);
  const [scClaimed, setscClaimed] = React.useState(null);
  const [selectedClaim, setSelectedClaim] = React.useState(null);

  // Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [option, setOptions] = React.useState("");
  
  const [claimOption, setClaimOption] = React.useState("sc");
  

  useEffect(() => {

    setLoading(false)

  }, [props]);

  const handleInputChange = (event) => {
    setClaimOption(event.target.value);
    setProduct(null)
  };

  const handleIMEINo = (e) => {
    setIMEINo(e.target.value.trim())
  }

  const handleSubmit = () => {

    setProduct(null)
    setIsIMEINo(false)
    setLoading(true)

    if(IMEINo.length > 8 ){
      setIsIMEINo(false)

      get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {

        if(snapshot.exists()){
          let IMEINumberfound =null;
          snapshot.forEach(val => {

            if(val.val().imeino=== IMEINo.trim()){
                
              if(claimOption === "sc" && val.val().sc === true)
              {
                IMEINumberfound = val.val();
              }

              if(claimOption === "ew" && val.val().ew === true)
              {
                IMEINumberfound = val.val();
              }
            }
              
          })

          if(IMEINumberfound)
          {
            get(child(dbref(rtdb), claimOption === "sc" ? `sc_claimed`: `ew_claimed`)).then((snapshot2) => {

              let IMEINumberfoundinClaim = [];

              if (snapshot2.exists()) {
                
                snapshot2.forEach(val => {

                  if(val.val().imeino === IMEINo.trim()){
                      
                    IMEINumberfoundinClaim.push(val.val());
                  }
                    
                })

                if(IMEINumberfoundinClaim.length < 1){
                  IMEINumberfoundinClaim = null;
                }
                setscClaimed(IMEINumberfoundinClaim)
              }
              else{
                IMEINumberfoundinClaim = null;
                setscClaimed(IMEINumberfoundinClaim)
              }

              if(claimOption === "sc")
              {
                if(moment(IMEINumberfound.purchase_date).isBefore(moment()) &&
                moment(IMEINumberfound.purchase_date).add(12, 'M').isAfter(moment()))
                  {
                    setClaimButtonDisable(false)
                  }
                  else
                  {
                    setClaimButtonDisable(true)
                  }
              }

              if(claimOption === "ew")
              {
                if(moment(IMEINumberfound.purchase_date).add(12, 'M').isBefore(moment()) && 
                moment(IMEINumberfound.purchase_date).add(24, 'M').isAfter(moment()))
                {
                  setClaimButtonDisable(false)
                }
                else
                {
                  setClaimButtonDisable(true)
                }
              }

              

              setProduct(IMEINumberfound)
              setIsIMEINo(false)
              setLoading(false)
            })

            
          }
          else
          {
            setIsIMEINo(true)
            setLoading(false)
          }
          
        }else{
          setIsIMEINo(true)
          setLoading(false)
        }

      })
      
    }
    else{
      setIsIMEINo(true)
      setLoading(false)
    }
  }

  const renderComponent = () => {

    let component  = <Grid />

    if(option === "sc"){
      component = <SC claimoption={claimOption} product={product} resubmitId={resubmitId} handleClose={handleClose}/>
    }

    if(option === "ew"){
      component = <SC claimoption={claimOption} product={product} resubmitId={resubmitId} handleClose={handleClose}/>
    }

    if(option === "sc_repair_details"){
      component = <SCRepairDetail claimoption={claimOption} product={product} resubmitId={resubmitId}  selectedClaim={selectedClaim} handleClose={handleClose}/>
    }

    return component
    
  }

  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleSubmitClaim = () => {

    setResubmitId(null)
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Submit Claim")
    setOptions(claimOption)
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
    
  }

  const handleReSubmitClaim = (val) => {

    setResubmitId(val.id)
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Submit Claim")
    setOptions(claimOption)
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
    
  }

  const handleSubmitRepairDetails = (val) => {

    setSelectedClaim(val)
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Submit Repair Details")
    setOptions("sc_repair_details")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const handleReSubmitRepairDetails = (val) => {

    setResubmitId(val.id)
    setSelectedClaim(val)
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Submit Repair Details")
    setOptions("sc_repair_details")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const renderClaimButton = (val) => {

    let item = null;

    if(val.status === "approved"){
      item = <Grid><Button
          style={{ textTransform: 'none', width: "100%", marginTop: 10, backgroundColor: "orange", }}
          fullWidth
          variant="contained"
          disableElevation
          onClick={()=> handleSubmitRepairDetails(val)}
        >
        Submit Repair Details
      </Button>
      </Grid>
    }

    else if(val.status === "cancelled"){
      item = <Grid><Button
        style={{textTransform: 'none', width: "100%", marginTop: 10, backgroundColor: "orangered" }}
        fullWidth
        variant="contained"
        disableElevation
        onClick={()=> handleReSubmitClaim(val)}
      >
        Claim Now
      </Button>
        <Typography style={{ fontSize: 10, marginTop: 5}}>Your submitted claim was cancelled, Please re-submit again.</Typography>
      </Grid>
    }

    else if(val.status === "seek_verification"){
      item = <Grid><Button
        style={{textTransform: 'none', width: "100%", marginTop: 10, backgroundColor: "orangered" }}
        fullWidth
        variant="contained"
        disableElevation
        onClick={()=> handleReSubmitClaim(val)}
      >
        Claim Now
      </Button>
        <Typography style={{ fontSize: 10, marginTop: 5 }}>Seek Verification, Please re-submit again.</Typography>
      </Grid>
    }
    
    else if(val.status === "seek_verification_repair_detail"){
      item = <Grid><Button
      style={{textTransform: 'none', width: "100%", marginTop: 10, backgroundColor: "orange", }}
      fullWidth
      variant="contained"
      disableElevation
      onClick={()=> handleReSubmitRepairDetails(val)}
      >
      Submit Repair Details
      </Button>
      <Typography style={{ fontSize: 10, marginTop: 5 }}>Seek Verification Repair Details, Please re-submit again.</Typography>
      </Grid>
    }

    else if(val.status === "submit_for_approval")
    {
      item = <Grid><Button
          disabled
          style={{textTransform: 'none', width: "100%", marginTop: 10, backgroundColor: "#dadada", }}
          fullWidth
          variant="contained"
          disableElevation
          onClick={()=> handleSubmitRepairDetails(val)}
        >
        Submit Repair Details
      </Button>
      <Typography style={{ fontSize: 10, marginTop: 5 }}>Your Claim is being reviewed for approval</Typography>
      </Grid>
    }

    if(val.status === "approved")
    {
      item = <Grid><Button
          style={{textTransform: 'none', width: "100%", marginTop: 10, backgroundColor: "orange", }}
          fullWidth
          variant="contained"
          disableElevation
          onClick={()=> handleSubmitRepairDetails(val)}
        >
        Submit Repair Details
      </Button>
      <Typography style={{ fontSize: 10, marginTop: 5 }}>Your Claim has been approved</Typography>
      </Grid>
    }

    if(val.status === "rejected"){
      item = <Grid>
          <Typography style={{ fontSize: 10, marginTop: 5, color: "red" }}>Rejected Claim</Typography>
          </Grid>;
    }

    if(val.status === "submit_for_repair_detail"){
      item = <Grid>
          <Typography style={{ fontSize: 10, marginTop: 5 }}>Repair Details Submitted</Typography>
          </Grid>;
    }

    if(val.status === "seek_verification" && val.resubmitted){
      item = <Grid>
      <Typography style={{ fontSize: 10, marginTop: 5 }}>Seek for Verification</Typography>
      </Grid>;
    }

    if(val.status === "closed"){
      item = <Grid>
      <Typography style={{ fontSize: 10, marginTop: 5 }}>Closed</Typography>
      </Grid>;
    }

    if(val.status === "cancelled" && val.resubmitted){
      item = <Grid>
      <Typography style={{ fontSize: 10, marginTop: 5 }}>Cancelled</Typography>
      </Grid>;
    }
    
    return item;
  }

  return (
    <Grid container >

      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ display: "flex", justifyContent: "flex-end"}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            
          </Toolbar>
        </AppBar>

        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          {renderComponent()}
        </DialogContent>
      </Dialog>

      <Grid item component={Paper} elevation={0} style={{ width: "100%", marginTop: 50, display:"flex", 
        justifyContent: "center", alignItems: "center" }}>
        <Grid>
              {loading ? 
              <Grid>
                <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', padding: 80  }}>
                  <CircularProgress 
                    disableShrink
                    color="primary" />
                </div>
              </Grid>
              :
              <Grid>
                <Typography variant='body2' style={{ marginTop: 10, marginBottom: 10}}>
                  Please input IMEI No. to begin search
                </Typography>

                
                <FormControl variant="outlined" fullWidth>
                  <Select
                    labelId="option-simple-select-outlined-label"
                    id="option-simple-select-outlined"
                    value={claimOption}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={"sc"}>Screen Replacement</MenuItem>
                    <MenuItem value={"ew"}>Extended Warranty</MenuItem>
                  </Select>

                  <TextField
                    style={{ marginTop: 10 }}
                      error={isIMEINo}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete='off'
                      id="IMEINo"
                      name="IMEINo"
                      size="small"
                      value={IMEINo}
                      onChange={handleIMEINo}
                    />
                    <Typography style={{ fontSize: 10, marginTop: 5, paddingLeft: 10 }}>e.g. 350017082283988</Typography>
                  {isIMEINo ? <Typography variant='body2' color="error" style={{ marginBottom: 10, fontSize: 11 }}>
                  IMEI Not Found.
                </Typography> : null}

                </FormControl>

                <Button
                  style={{textTransform: 'none', width: "100%", marginTop: 10 }}
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={()=> handleSubmit()}
                >
                Search
                </Button>

                {product ? <Grid style={{ marginTop: 20 }}>

                  <Divider style={{  opacity: .2 }}/>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`IMEI No : ${(product.imeino ? product.imeino : "-")}`}
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Serial No : ${(product.serialno ? product.serialno : "-")}`}
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Country : ${(product.country ? product.country : "-")}`}
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Product Brand : Apple`}
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Product Model : ${(product.phone_model ? product.phone_model : "-")}`}
                  </Typography>

                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Product Color : ${(product.phone_color ? product.phone_color : "-")}`}
                  </Typography>

                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Product Capacity : ${(product.phone_capacity ? product.phone_capacity : "-")}`}
                  </Typography>
                  
                  {claimOption === "sc" ? <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Activation Start Date : ${(product.purchase_date ? 
                      moment(product.purchase_date).format("DD-MM-YYYY") : "-")}`}
                  </Typography> :  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Activation Start Date : ${(product.purchase_date ? 
                      moment(product.purchase_date).add(12, 'M').format("DD-MM-YYYY") : "-")}`}
                  </Typography> }

                  {claimOption === "sc" ? <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Coverage End Date : ${(product.purchase_date ? 
                      moment(product.purchase_date).add(12, 'M').format("DD-MM-YYYY") : "-")}`}
                  </Typography> :  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    {`Coverage End Date : ${(product.purchase_date ? 
                      moment(product.purchase_date).add(24, 'M').format("DD-MM-YYYY") : "-")}`}
                  </Typography> }

                  {scClaimed ? scClaimed.map(val=>{
                    return <Grid style={{ marginTop: 30 }}>
                        <Typography style={{ fontSize: 12, marginTop: 5 }}>{`ID: ${val.id}`}</Typography>
                        <Typography style={{ fontSize: 12, marginTop: 5 }}>{`Submission Date:  ${new Date(val.created*1000).getFullYear()}
                        -${new Date(val.created*1000).getMonth()+1}
                        -${new Date(val.created*1000).getDate()}`}</Typography>

                        {renderClaimButton(val)}
                      </Grid>
                  }): 
                  <Button
                    disabled={claimButtonDisable}
                    style={{textTransform: 'none', width: "100%", marginTop: 10, backgroundColor: claimButtonDisable? "lightgrey": "orangered" }}
                    fullWidth
                    variant="contained"
                    disableElevation
                    onClick={()=> handleSubmitClaim()}
                  >
                    Claim Now
                  </Button>}
                  
                </Grid> : null }

              </Grid>}
          </Grid>
      </Grid>

    </Grid>);
}


export default SignIn;