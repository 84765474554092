import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';

// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import navEditorConfig from './configEditor';
import navManagerConfig from './configManager';
import navClaimManagerConfig from './configClaimManager';
import navClaimStaffConfig from './configClaimStaff';
import { UserAuth } from '../../../context/AuthContext';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const { user } = UserAuth();

  const [userRole, setUserRole] = useState("");

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }

    if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
      let _role = "";
      
      if(JSON.parse(user.reloadUserInfo.customAttributes).role === "super_admin")
      {
        _role = "Super Admin"
      }
      else if(JSON.parse(user.reloadUserInfo.customAttributes).role === "editor")
      {
        _role = "Editor"
      }
      else if(JSON.parse(user.reloadUserInfo.customAttributes).role === "manager")
      {
        _role = "Manager"
      }
      else if(JSON.parse(user.reloadUserInfo.customAttributes).role === "claim_manager")
      {
        _role = "Claim Manager"
      }
      else if(JSON.parse(user.reloadUserInfo.customAttributes).role === "claim_staff")
      {
        _role = "Claim Staff"
      }
      setUserRole(_role)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderNav = () => {

    let _nav = navEditorConfig;
    if(userRole === "Super Admin"){
      _nav = navConfig
    }
    else if(userRole === "Editor"){
      _nav =  navEditorConfig
    }
    else if(userRole === "Manager"){
      _nav =  navManagerConfig
    }
    else if(userRole === "Claim Manager"){
      _nav =  navClaimManagerConfig
    }
    else if(userRole === "Claim Staff"){
      _nav =  navClaimStaffConfig
    }
    else{
      _nav =  navEditorConfig
    }
    
    return _nav
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={user.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user.displayName}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userRole}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={renderNav()} />

      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
