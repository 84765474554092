import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import * as FileSaver from 'file-saver';
import * as React from 'react';
import XLSX from 'sheetjs-style';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import moment from 'moment'
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Tooltip,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  TextField,
  Grid
} from '@mui/material';

import Divider from '@mui/material/Divider';

import Link from '@mui/material/Link';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import dayjs from 'dayjs';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { getFirestore, doc, setDoc, serverTimestamp, Timestamp, getDocs, collection, query, where } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/claim';

import { UserAuth } from '../context/AuthContext'

const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const rtdb = getDatabase(app);

const svc = {
  "BN000001": {
    "address": "Unit 27/28, Block B, Ground & First Floor, Bangunan Awang Haji Ahmad Bin Awang Hassan & Anak-Anak, Kampong Kiulap, Bandar Seri Begawan, BE1518, Brunei Darussalam",
    "country": "Brunei",
    "id": "BN000001",
    "name": "AV Electronics Sdn Bhd"
  },
  "IP000001": {
    "address": "7500A Beach Rd, #05-319 The Plaza, Singapore 199591",
    "country": "Singapore",
    "id": "IP000001",
    "name": "InstaProtection"
  },
  "LK000001": {
    "address": "Generation Next Communications Lanka (Pvt) Ltd., 429/A, Thimbirigasyaya Rd, Colombo 05, Sri Lanka",
    "country": "Sri Lanka",
    "id": "LK000001",
    "name": "Apple Service Center - GENXT SL"
  },
  "MM000001": {
    "address": "No.81, Kabar Aye Pagoda Road, Bahan Township, Yangon, Myanmar.",
    "country": "Myanmmar",
    "id": "MM000001",
    "name": "mCare"
  },
  "MN000001": {
    "address": "Mongolia",
    "country": "mongolia",
    "id": "MN000001",
    "name": "AASP of Mobicom Corporation"
  },
  "NP000001": {
    "address": "House No 484, Bakhundole Marg. Ward No 1 Lalitpur 44700 Kathmandu, Nepal",
    "country": "Nepal",
    "id": "NP000001",
    "name": "Evolution Trading Pvt Ltd."
  },
  "NP000002": {
    "address": "2nd Floor Sherpa Mall, Durbar Marg, Kathmandu, Nepal",
    "country": "Nepal",
    "id": "NP000002",
    "name": "Generation Next Communication Pvt Ltd."
  }
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Claim ID', alignRight: false },
  { id: 'created', label: 'Created Date/Time', alignRight: false },
  { id: 'purchase_date', label: 'Activation Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'customer_name', label: 'Full Name', alignRight: false },
  { id: 'serialno', label: 'Serial No', alignRight: false },
  { id: 'country', label: 'Country', alignRight: false },
  { id: 'servicecenter', label: 'SVC', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy2) {
  if (b[orderBy2] < a[orderBy2]) {
    return -1;
  }
  if (b[orderBy2] > a[orderBy2]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy2) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy2)
    : (a, b) => -descendingComparator(a, b, orderBy2);
}

function applySortFilter(array, comparator, query, select) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {

    if(select === "customer_name"){
      return filter(array, (_user) => _user.customer_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    
    if(select === "id"){
      return filter(array, (_user) => _user.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if(select === "customer_email"){
      return filter(array, (_user) => _user.customer_email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if(select === "serialno"){
      return filter(array, (_user) => _user.serialno.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if(select === "customer_phone"){
      return filter(array, (_user) => _user.customer_phone.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = '.xlsx';

export default function ProductPage() {

  const { user } = UserAuth()

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy2, setOrderBy2] = useState('created');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [row, setRow] = useState([]);

  const [fileUrl1, setFileUrl1] = useState(null);
  const [fileUrl2, setFileUrl2] = useState(null);
  const [fileUrl3, setFileUrl3] = useState(null);
  const [fileUrl4, setFileUrl4] = useState(null);
  const [fileUrl5, setFileUrl5] = useState(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [selectedStatus, setSelectedStatus] = useState('');
  const [userRole, setUserRole] = useState('super_admin');
  const [verifymessage, setVerifymessage] = useState('');
  
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const handleChangeStatus = (event) => {
    setSelectedStatus(event.target.value);
  };

  const [select, setSelect] = useState('id');

  const handleChangeSelect = (event) => {
    setSelect(event.target.value);
  };

  const handleChangeMessage = (event) => {
    setVerifymessage(event.target.value);
  };


  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [dialogOpen3, setDialogOpen3] = useState(false);
  const [dialogOpen4, setDialogOpen4] = useState(false);
  const [dialogOpen5, setDialogOpen5] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogClose2 = () => {
    setDialogOpen2(false);
  };

  const handleDialogClose3 = () => {
    setDialogOpen3(false);
  };

  const handleDialogClose4 = () => {
    setDialogOpen4(false);
  };

  const handleDialogClose5 = () => {
    setDialogOpen5(false);
  };


  const handleDialogSubmit = () => {

    update(dbref(rtdb, selectedItem.sc ? `sc_claimed/${selectedItem.id}`: `ew_claimed/${selectedItem.id}`), {
      status: selectedStatus
    }).then(()=>{
      let emailtxt = "";

      if(selectedStatus === "submit_for_approval")
      {
          emailtxt = "Thank you for using the InstaProctection insurance. We are processing your application."
      }
      else if(selectedStatus === "approved")
      {
          emailtxt = "Thank you for using the InstaProctection insurance. Your submission has been accepted."
      }
      else if(selectedStatus === "approved_repair_detail")
      {
          emailtxt = "Thank you for using the InstaProctection insurance. Your repair detail has been accepted."
      }
      else if(selectedStatus === "cancelled")
      {
          emailtxt = "Thank you for using the InstaProctection insurance. Your submission has been cancelled."
      }
      else if(selectedStatus === "rejected")
      {
          emailtxt = "Thank you for using the InstaProctection insurance. Your submission has been rejected."
      }
      else if(selectedStatus === "seek_verification")
      {
          emailtxt = "Thank you for using the InstaProctection insurance. "

          sendEmail(emailtxt, selectedItem.customer_email, selectedItem.customer_name, selectedItem)
      }
    })
    
    setDialogOpen(false); 
  };

  const sendEmail = (text, email, customerName, product) => {
      const emailID = `Claim-${Timestamp.fromDate(new Date()).toMillis()}`;
          try
          {
            setDoc(doc(db, "mail", emailID), {
              to: email !== null || email !== "" ? 
              [email, user.email]:
              [user.email],
              message: {
                subject: 'Gnext - Acknowledgement of Update Insurance',
                html: `<!DOCTYPE html>
                <html>
                <head>
                
                  <meta charset="utf-8">
                  <meta http-equiv="x-ua-compatible" content="ie=edge">
                  <title>Gnext - Acknowledgement of Update Insurance</title>
                  <meta name="viewport" content="width=device-width, initial-scale=1">
                  <style type="text/css">
                  /**
                   * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
                   */
                  @media screen {
                    @font-face {
                      font-family: 'Source Sans Pro';
                      font-style: normal;
                      font-weight: 400;
                      src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
                    }
                    @font-face {
                      font-family: 'Source Sans Pro';
                      font-style: normal;
                      font-weight: 700;
                      src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
                    }
                  }
                  /**
                   * Avoid browser level font resizing.
                   * 1. Windows Mobile
                   * 2. iOS / OSX
                   */
                  body,
                  table,
                  td,
                  a {
                    -ms-text-size-adjust: 100%; /* 1 */
                    -webkit-text-size-adjust: 100%; /* 2 */
                  }
                  /**
                   * Remove extra space added to tables and cells in Outlook.
                   */
                  table,
                  td {
                    mso-table-rspace: 0pt;
                    mso-table-lspace: 0pt;
                  }
                  /**
                   * Better fluid images in Internet Explorer.
                   */
                  img {
                    -ms-interpolation-mode: bicubic;
                  }
                  /**
                   * Remove blue links for iOS devices.
                   */
                  a[x-apple-data-detectors] {
                    font-family: inherit !important;
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important;
                    color: inherit !important;
                    text-decoration: none !important;
                  }
                  /**
                   * Fix centering issues in Android 4.4.
                   */
                  div[style*="margin: 16px 0;"] {
                    margin: 0 !important;
                  }
                  body {
                    width: 100% !important;
                    height: 100% !important;
                    padding: 0 !important;
                    margin: 0 !important;
                  }
                  /**
                   * Collapse table borders to avoid space between cells.
                   */
                  table {
                    border-collapse: collapse !important;
                  }
                  a {
                    color: #1a82e2;
                  }
                  img {
                    height: auto;
                    line-height: 100%;
                    text-decoration: none;
                    border: 0;
                    outline: none;
                  }
                  </style>
                
                </head>
                <body style="background-color: #e9ecef;">
                
                  <!-- start preheader -->
                  <div class="preheader" style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
                  ${text}
                  </div>
                  <!-- end preheader -->
                
                  <!-- start body -->
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                
                    <!-- start logo -->
                    <tr>
                      <td align="center" bgcolor="#e9ecef">
                        <!--[if (gte mso 9)|(IE)]>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                        <tr>
                        <td align="center" valign="top" width="600">
                        <![endif]-->
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                          <tr>
                            <td align="center" valign="top" style="padding: 36px 24px;">
                              <a href="https://genxtservices.com" target="_blank" style="display: inline-block;">
                                <img src="https://firebasestorage.googleapis.com/v0/b/genxt-9f006.appspot.com/o/logo%2FGenxt-logo.png?alt=media&token=26854a16-dec8-48c7-bfb7-bd509fd23e3b" alt="Logo" border="0" width="150" style="display: block; width: 150px; max-width: 150px; min-width: 48px;">
                              </a>
                            </td>
                          </tr>
                        </table>
                        <!--[if (gte mso 9)|(IE)]>
                        </td>
                        </tr>
                        </table>
                        <![endif]-->
                      </td>
                    </tr>
                    <!-- end logo -->
                
                    <!-- start copy block -->
                    <tr>
                      <td align="center" bgcolor="#e9ecef">
                        <!--[if (gte mso 9)|(IE)]>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                        <tr>
                        <td align="center" valign="top" width="600">
                        <![endif]-->
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
  
                        <!-- start copy -->
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                              <p style="margin: 0;">Dear ${customerName},</p>
                            </td>
                          </tr>
                          <!-- end copy -->
                
                          <!-- start copy -->
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                              <p style="margin: 0;">${text}</p>
                            </td>
                          </tr>
  
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                              <p style="margin: 0;">${selectedStatus === "seek_verification" 
                                || selectedStatus === "seek_verification_repair_detail" ? verifymessage : ""}</p>
                            </td>
                          </tr>
                          <!-- end copy -->
                
                          <!-- start copy -->
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                              <p style="margin: 0;">Application reference no: ${product.id}</p>
                              <p style="margin: 0;">IMEI No: ${product.imeino}</p>
                              <p style="margin: 0;">Serial No: ${product.serialno}</p>
                              <p style="margin: 0;">Product Model: ${product.phone_model}</p>
                              <p style="margin: 0;">Product Color: ${product.phone_color}</p>
                              <p style="margin: 0;">Product Capacity: ${product.phone_capacity}</p>
                              <p></p>
                              <p style="margin: 0;">Please do not reply to this email.</p>
                            </td>
                          </tr>
                          <!-- end copy -->
                
                          <!-- start copy -->
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                              <p style="margin: 0;">Cheers,<br> Gnext </p>
                            </td>
                          </tr>
                          <!-- end copy -->
  
                          <!-- start copy -->
                          <tr>
                            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                              <p style="margin: 0;">Date: ${moment().format("DD-MM-YYYY HH:mm")}</p>
                            </td>
                          </tr>
                          <!-- end copy -->
                
                        </table>
                        <!--[if (gte mso 9)|(IE)]>
                        </td>
                        </tr>
                        </table>
                        <![endif]-->
                      </td>
                    </tr>
                    <!-- end copy block -->
                
                  </table>
                  <!-- end body -->
                
                </body>
                </html>`,
              }
            })
  
        }
        catch(e)
        {
          // console.log(e)
        }
    }

  const leftPad = (number, targetLength) => {
    let output = `${number}`;
    while (output.length < targetLength) {
        output = `0${output}`;
    }
    return output;
  }

  useEffect(() => {


    let _user = "";
    if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
      _user = JSON.parse(user.reloadUserInfo.customAttributes);
    }
    setUserRole(_user.role)

    const arr = [];
    get(child(dbref(rtdb), `ew_claimed`)).then((snapshot) => {
        if (snapshot.exists()) {

          snapshot.forEach((doc) => {

            if(_user.role === "super_admin"){
              arr.push({
                ...doc.val(),
                ew: true,
              });
            }
            
            if(_user.role === "claim_manager" && _user.country === doc.val().country && _user.servicecenter ===  doc.val().servicecenter)
            {
              arr.push({
                ...doc.val(),
                ew: true,
              });
            }
           
            
            })

            setRow(arr);
            setLoading(false);

        } 

        
      }).catch((error) => {
        console.error(error);
    }); 

   
    /* setRow([]);
    setLoading(false);

    let sequenceID = 0;

    get(child(dbref(rtdb), `device-registration`)).then((snapshot1) => {
      const arr = [];
      snapshot1.forEach((doc) => {
        arr.push({
          ...doc.val()
        });
      })

      get(child(dbref(rtdb), `ew_claimed_1`)).then((snapshot) => {
        if (snapshot.exists()) {
          const arr2 = [];
          snapshot.forEach((doc) => {

            const itemsVal = doc.val();
            const _itemsVal = Object.values(itemsVal);
              
            const _itemsVal2 = Object.fromEntries(
              Object.entries(_itemsVal)
                .filter(item => item[1] !== "")
            );

            const _itemsVal3 = Object.values(_itemsVal2)
            
            _itemsVal3.forEach(val =>{

              arr2.push(val)

            })
        
          }) 

          arr2.sort((a,b) => Number(a.created) - Number(b.created)).forEach((val) => {
            const currentindex = arr.map(val=>Number(val.imeino)).indexOf(Number(val.imei_no));
            
            if(currentindex !== -1)
            {

              sequenceID += 1;
              const values = {
                city: arr[currentindex].city ?  arr[currentindex].city : "",
                country: arr[currentindex].country || "",
                created: val.created ? Number(val.created).toString().substring(0, 10) : "",
                createdby: val.submit_user || "",
                customer_email:val.customer_email || "",
                customer_name:val.customer_name || "",
                customer_phone:val.customer_phone || "",
                id:val.claim_id || "",
                incident_date:val.incident_date || "",
                incident_detail:val.incident_detail || "",
                incident_location:val.incident_location || "",
                phone_capacity: arr[currentindex].phone_capacity || "",
                phone_color:arr[currentindex].phone_color || "",
                phone_model:arr[currentindex].phone_model || "",
                phone_partno:arr[currentindex].phone_partno || "",
                repair_detail_created: val.repair_detail_created ? Number(val.repair_detail_created).toString().substring(0, 10) : "",
                repair_detail_createdby:val.submit_user_repair_details || "",
                post_repair_img_contentType:val.post_repair_image ? val.post_repair_image.type : "",
                post_repair_img_fullPath:val.post_repair_image ? val.post_repair_image.url : "",
                repair_invoice_img_contentType:val.repair_invoice ? val.repair_invoice.type : "",
                repair_invoice_img_fullPath:val.repair_invoice ? val.repair_invoice.url : "",
                service_repair_report_img_contentType:val.service_repair_report ? val.service_repair_report.type : "",
                service_repair_report_img_fullPath:val.service_repair_report ? val.service_repair_report.url : "",
                serialno:arr[currentindex].serialno || "",
                imeino: val.imei_no || "",
                repair_date: val.repair_date || "",
                status:val.status || "",
                updated: Number(val.updated).toString().substring(0, 10) || "",
                pre_repair_contentType:val.pre_repair_image.type || "",
                pre_repair_fullPath:val.pre_repair_image.url || "",
                purchase_invoice_contentType:val.purchase_invoice.type || "",
                purchase_invoice_fullPath:val.purchase_invoice.url || "",
                purchase_date:arr[currentindex].purchase_date || "",
                repair_amount: Number(val.repair_amount) || "",
                servicecenter: svc[val.submit_user.service_center_id === "MM000003" ? "MM000001" : val.submit_user.service_center_id].name
              }

              update(child(dbref(rtdb), `ew_claimed/${val.claim_id}`), values)

              console.log(val.claim_id)

            } 
          })
  
        } 
  
      }) 
      
    }) */
    

    

    // update(child(dbref(rtdb), `sc_claimed-1`), { da: "agag" })

  }, [])

  const handleSubmitDownloadAll = async () => {

    setDialogLoading(true);

    const _newRow = []
    row.forEach(val => {
      const _item = {};
      _item["Registration ID"] = val.id;
      _item["Registration Date/Time"] = `${new Date(val.created*1000).getFullYear()}-${new Date(val.created*1000).getMonth()+1}-${new Date(val.created*1000).getDate()} (${new Date(val.created*1000).getHours()}: ${new Date(val.created*1000).getMinutes()})`;
      _item["Activation Date"] = val.purchase_date;
      _item.Owner = val.customer_name;
      _item["Email Address"] = val.customer_email;
      _item["Mobile Number"] = val.customer_phone;
      _item["Serial No."] = val.serialno;
      _item["IMEI No."] = val.imeino;
      _item["Incident Date"] = val.incident_date;
      _item["Incident Detail"] = val.incident_detail;
      _item["Incident Location"] = val.incident_location;
      _item.Status = val.status;
      _item.Country = val.country;
      _item.City = val.city;
      _item["Repair Amount"] = val.repair_amount;
      _item["Part No"] = val.phone_partno;
      _item["Phone Model"] = val.phone_model;
      _item["Phone Color"] = val.phone_color;
      _item["Phone Capacity"] = val.phone_capacity;
      

      _newRow.push(_item)
    })


    const ws = XLSX.utils.json_to_sheet(_newRow);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Claim_submitted_data${fileExtension}`); 

    setDialogOpen5(false);
    setDialogLoading(false); 

  }


  const handleSubmitDownload = async () => {

    setDialogLoading(true);

    const _newRow = []
    row.forEach(val => {
      if(moment(val.purchase_date).isSameOrAfter(startDate.format('YYYY-MM-DD')) 
        && moment(val.purchase_date).isSameOrBefore(endDate.format('YYYY-MM-DD')))
        {
          const _item = {};
          _item["Registration ID"] = val.id;
          _item["Registration Date/Time"] = `${new Date(val.created*1000).getFullYear()}-${new Date(val.created*1000).getMonth()+1}-${new Date(val.created*1000).getDate()} (${new Date(val.created*1000).getHours()}: ${new Date(val.created*1000).getMinutes()})`;
          _item["Activation Date"] = val.purchase_date;
          _item.Owner = val.customer_name;
          _item["Email Address"] = val.customer_email;
          _item["Mobile Number"] = val.customer_phone;
          _item["Serial No."] = val.serialno;
          _item["IMEI No."] = val.imeino;
          _item["Incident Date"] = val.incident_date;
          _item["Incident Detail"] = val.incident_detail;
          _item["Incident Location"] = val.incident_location;
          _item.Status = val.status;
          _item.Country = val.country;
          _item.City = val.city;
          _item["Repair Amount"] = val.repair_amount;
          _item["Part No"] = val.phone_partno;
          _item["Phone Model"] = val.phone_model;
          _item["Phone Color"] = val.phone_color;
          _item["Phone Capacity"] = val.phone_capacity;
          

          _newRow.push(_item)
        }
    })


    const ws = XLSX.utils.json_to_sheet(_newRow);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Claim_submitted_data${fileExtension}`); 

    setDialogOpen2(false);
    setDialogLoading(false); 

  }

  const handleSubmitDownloadCreatedDate = async () => {

    setDialogLoading(true);

    const _newRow = []


    row.forEach(val => {

      if(moment.unix(val.created).isSameOrAfter(startDate.format('YYYY-MM-DD')) 
      && moment.unix(val.created).isSameOrBefore(endDate.format('YYYY-MM-DD')))
      {
        const _item = {};
        _item["Registration ID"] = val.id;
        _item["Registration Date/Time"] = `${new Date(val.created*1000).getFullYear()}-${new Date(val.created*1000).getMonth()+1}-${new Date(val.created*1000).getDate()} (${new Date(val.created*1000).getHours()}: ${new Date(val.created*1000).getMinutes()})`;
        _item["Activation Date"] = val.purchase_date;
        _item.Owner = val.full_name;
        _item["Email Address"] = val.email;
        
        _item["Serial No."] = val.serialno;
        _item["IMEI No."] = val.imeino;
        _item.Country = val.country;
        _item.City = val.city;
        _item["Repair Amount"] = val.repair_amount;
        _item["Phone Model"] = val.phone_model;
        _item["Phone Color"] = val.phone_color;
        _item["Phone Capacity"] = val.phone_capacity;
        _item.Warranty = "Screen Replacement Warranty";
 
 
        _newRow.push(_item)
      }
      
    })


    const ws = XLSX.utils.json_to_sheet(_newRow);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `claim_submission_data_created_date${fileExtension}`); 

    setDialogOpen3(false);
    setDialogLoading(false);

  }

  const downloadExcel = async () => {
    setDialogOpen2(true);
  }

  const downloadExcelCreatedDate = async () => {
    setDialogOpen3(true);
  }

  const downloadExcelAll = async () => {
    setDialogOpen5(true);
  }

  const handleOnDetails = () => {
    setOpenDetails(true);
    setOpen(false)
  }

  const handleOnEdit = () => {
    setDialogOpen(true);
    setOpen(false)
  }

  const handleOpenMenu = (event, row) => {
    setSelectedItem(row)
    setOpen(event.currentTarget);
    setSelectedStatus(row.status)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy2 === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy2(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = row.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - row.length) : 0;

  const filteredUsers = applySortFilter(row, getComparator(order, orderBy2), filterName, select);

  const isNotFound = !filteredUsers.length && !!filterName;

  if(loading){
    return (
      
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }


  return (
    <>
      <Helmet>
        <title> EW Claim Submission | Genxt </title>
      </Helmet>

      <Dialog
        open={openDetails}
        onClose={handleCloseDetails}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Registration Details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              {selectedItem ? <Grid style={{ width: "100%", display: "flex",
                flexDirection: "row" }} ><Grid style={{ width: "100%", display: "flex",
                flexDirection: "column", paddingRight: 30 }}>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Claim ID
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem && selectedItem.id ? selectedItem.id : "-"}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Created Date/Time
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 14 }}>{`${new Date(selectedItem.created*1000).getFullYear()}
                -${new Date(selectedItem.created*1000).getMonth()+1}
  -${new Date(selectedItem.created*1000).getDate()} ${new Date(selectedItem.created*1000).getHours()}: ${new Date(selectedItem.created*1000).getMinutes()}`}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Activation Date
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.purchase_date}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Full Name
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.customer_name}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                 Phone Model
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.phone_model}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                 Phone Color
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.phone_color}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                 Phone Capacity
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.phone_capacity}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Email
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.customer_email}
                  </Typography>


              </Grid>
              <Grid style={{ width: "100%", display: "flex",
                flexDirection: "column" }}>



                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  City
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.city}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Serial No.
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.serialno}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Country
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.country}
                  </Typography>


                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Status
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.status}
                  </Typography>

                  {selectedItem.warranty_card_fullPath ? <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Purchase Invoice
                  </Typography> : null}
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.warranty_card_fullPath ? <Link target='_blank' href={selectedItem.warranty_card_fullPath}>Link</Link>: null}
                  </Typography>

                  {selectedItem.pre_repair_img_fullPath ? <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Pre Repair Image
                  </Typography> : null}
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.pre_repair_img_fullPath ? <Link target='_blank' href={selectedItem.pre_repair_img_fullPath}>Link</Link> : null}
                  </Typography>

                  {selectedItem.repair_img_fullPath? <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Repair Image
                  </Typography> : null}
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.repair_img_fullPath ? <Link target='_blank' href={selectedItem.repair_img_fullPath}>Link</Link> : null}
                  </Typography>

                  {selectedItem.repair_invoice_img_fullPath ? <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Repair Invoce
                  </Typography> : null}
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.repair_invoice_img_fullPath ? <Link target='_blank' href={selectedItem.repair_invoice_img_fullPath}>Link</Link> : null}
                  </Typography>

                  {selectedItem.repair_report_img_fullPath ? <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Repair Report
                  </Typography> : null}
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.repair_report_img_fullPath ? <Link target='_blank' href={selectedItem.repair_report_img_fullPath}>Link</Link> : null}
                  </Typography>


              </Grid>
              </Grid> : null }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen2}
        onClose={handleDialogClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Download Excel"}
        </DialogTitle>
        {dialogLoading ? <DialogContent>
          <CircularProgress color="inherit" />
          </DialogContent> : <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select the activation date range
          </DialogContentText>
          <Grid style={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="End Date" 
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>
          </Grid>
        </DialogContent>}
        {dialogLoading ? null : <DialogActions>
          <Button onClick={handleDialogClose2}>Cancel</Button>
          <Button onClick={handleSubmitDownload} autoFocus>
            Download
          </Button>
        </DialogActions> }
      </Dialog>

      <Dialog
        open={dialogOpen5}
        onClose={handleDialogClose5}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Download Excel"}
        </DialogTitle>
        
        {dialogLoading ? null : <DialogActions>
          <Button onClick={handleDialogClose5}>Cancel</Button>
          <Button onClick={handleSubmitDownloadAll} autoFocus>
            Download
          </Button>
        </DialogActions> }
      </Dialog>

      <Dialog
        open={dialogOpen3}
        onClose={handleDialogClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Download Excel (Created Date)"}
        </DialogTitle>
        {dialogLoading ? <DialogContent>
          <CircularProgress color="inherit" />
          </DialogContent> : <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select the created date range
          </DialogContentText>
          <Grid style={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="End Date" 
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>
          </Grid>
        </DialogContent>}
        {dialogLoading ? null : <DialogActions>
          <Button onClick={handleDialogClose3}>Cancel</Button>
          <Button onClick={handleSubmitDownloadCreatedDate} autoFocus>
            Download
          </Button>
        </DialogActions> }
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit"}
        </DialogTitle>
        <DialogContent>
          <FormControl style={{ marginTop: 20 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="Status"
              id="Status"
              value={selectedStatus}
              label="Status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={"submit_for_approval"}>Submit For Approval</MenuItem>
              <MenuItem value={"seek_verification"}>Seek Verification</MenuItem>
              <MenuItem value={"submit_for_repair_detail"}>Submitted Repair Detail</MenuItem>
              <MenuItem value={"seek_verification_repair_detail"}>Seek Verification Repair Detail</MenuItem>
              <MenuItem value={"approved"}>Approve For Repair Detail</MenuItem>
              <MenuItem value={"cancelled"}>Cancelled</MenuItem>
              <MenuItem value={"rejected"}>Rejected</MenuItem>
              <MenuItem value={"payment_completed"}>Payment Completed</MenuItem>
              <MenuItem value={"closed"}>Closed</MenuItem>
            </Select>
          </FormControl>
          {selectedStatus === "seek_verification" 
                    || selectedStatus === "seek_verification_repair_detail" ?<Grid style={{ marginTop: 15}}>
                      <TextField
                        id="description"
                        label="Message"
                        multiline
                        value={verifymessage}
                        onChange={handleChangeMessage}
                        rows={4}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={handleDialogSubmit} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {selectedItem ? <Dialog
        open={dialogOpen4}
        fullScreen
        onClose={handleDialogClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ display: "flex", justifyContent: "flex-end"}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose4}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            
          </Toolbar>
        </AppBar>

        <DialogTitle id="alert-dialog-title">
          {selectedItem.id}
        </DialogTitle>
        <DialogContent>
          <Typography align='left' style={{ fontSize: 18, marginTop: 10, textDecoration: 'underline' }}>
          Policy Details
          </Typography>

          <Grid container style={{ marginTop: 10 }}>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Plan Name
                </Typography>
                

                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
               {selectedItem.sc ? "Screen Crack Warranty" : "Extended Warranty"}
                </Typography>

              </Grid>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Policy Number
                </Typography>
                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {selectedItem.id}
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Purchase Date
                </Typography>

                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {selectedItem.purchase_date}
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Coverage Period
                </Typography>

                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {`${selectedItem.sc ? moment(selectedItem.purchase_date, "YYYY-MM-DD").format("YYYY-MM-DD"): moment(selectedItem.purchase_date, "YYYY-MM-DD").add(1, "years").format("YYYY-MM-DD")} to ${selectedItem.sc ? moment(selectedItem.purchase_date, "YYYY-MM-DD").add(1, "years").format("YYYY-MM-DD"): moment(selectedItem.purchase_date, "YYYY-MM-DD").add(2, "years").format("YYYY-MM-DD")}`}
                </Typography>
              </Grid>

             

              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Purchased From
                </Typography>
                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                InstaProtection
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Plan Status
                </Typography>
                <Typography align='left' style={{ marginTop: 5,fontSize: 14 }}>
                {selectedItem.status}
                </Typography>
              </Grid>
          </Grid>

          <Typography align='left' style={{ fontSize: 18, marginTop: 10, textDecoration: 'underline' }}>
          User Details
          </Typography>
          <Grid container style={{ marginTop: 10 }}>
              <Grid item xs={12} md={2}>
                <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Region
                </Typography>
                

                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {selectedItem.country}
                </Typography>

              </Grid>

              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                User
                </Typography>

                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {selectedItem.customer_name}
                </Typography>

              </Grid>

              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Email
                </Typography>
                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {selectedItem.customer_email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Mobile
                </Typography>

                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {`+${selectedItem.customer_phone}`}
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                City
                </Typography>
                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {selectedItem.city ? selectedItem.city : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Created At
                </Typography>
                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {`${new Date(selectedItem.created*1000).getFullYear()}
                -${new Date(selectedItem.created*1000).getMonth()+1}
  -${new Date(selectedItem.created*1000).getDate()} ${new Date(selectedItem.created*1000).getHours()}: ${new Date(selectedItem.created*1000).getMinutes()}`}
                </Typography>
              </Grid>
          </Grid>

        
          <Typography align='left' style={{ fontSize: 18, marginTop: 10, textDecoration: 'underline' }}>
          Policy Details
          </Typography>
          <Grid container style={{ marginTop: 10 }}>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Device
                </Typography>
                

                <Typography align='left' style={{ marginTop: 10, fontSize: 14 }}>
              Apple
                </Typography>

              </Grid>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Model Name
                </Typography>
                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {selectedItem.phone_model.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Serial Number
                </Typography>

                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {selectedItem.serialno.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                IMEI Number
                </Typography>
                <Typography align='left' style={{ marginTop: 5, fontSize: 14 }}>
                {selectedItem.imeino ? selectedItem.imeino : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Device Color
                </Typography>
                <Typography align='left' style={{ marginTop: 5,fontSize: 14 }}>
                {selectedItem.phone_color.toUpperCase()}
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
              <Divider style={{ marginTop: 10, marginBottom: 5 }}/>
                <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                Device Capacity
                </Typography>
                <Typography align='left' style={{ marginTop: 5,fontSize: 14 }}>
                {selectedItem.phone_capacity.toUpperCase()}
                </Typography>
              </Grid>
          </Grid>

          <Typography align='left' style={{ fontSize: 18, marginTop: 10, textDecoration: 'underline' }}>
          Policy Activities
          </Typography>
          <Grid container >
              <Grid item xs={12} md={2}>
              
              <Grid container>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                  Action
                  </Typography>
                </Grid>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontSize: 11 }}>
                Claim Submitted 
                  </Typography>
                </Grid>
              </Grid>
              

               

              </Grid>
              <Grid item xs={12} md={4} style={{ flexDirection: "row"}}>
                <Grid container>
                  <Grid item xs={6} md={12}>
                    <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                    Attached
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Grid style={{ display: "flex", flexDirection: "row"}}>
                      <Grid>
                        <Typography align='left' style={{ fontSize: 11 }}>
                        Purchase Invoice
                        </Typography>
                        <Link key={selectedItem.warranty_card_fullPath} target='_blank' href={selectedItem.purchase_invoice_fullPath}>
                        { selectedItem.purchase_invoice_contentType === "application/pdf" ?"PDF Link" : <img key={selectedItem.purchase_invoice_fullPath} height={50} width={40} alt='file' src={selectedItem.purchase_invoice_fullPath}/>}
                        </Link>
                      </Grid>
                      
                      <Grid style={{ marginLeft: 20}}>
                        <Typography align='left' style={{ fontSize: 11 }}>
                        Pre Repair Image
                        </Typography>
                        <Link key={selectedItem.pre_repair_img_fullPath} target='_blank' href={selectedItem.pre_repair_fullPath}>
                        { selectedItem.pre_repair_contentType === "application/pdf" ?"PDF Link" : <img key={selectedItem.pre_repair_fullPath} height={50} width={40} alt='file' src={selectedItem.pre_repair_fullPath}/>}
                        </Link>
                      </Grid>
                      </Grid>
                  </Grid>
                </Grid>

              </Grid>

              <Grid item xs={12} md={2}>
              <Grid container>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                  Incident Details
                  </Typography>
                </Grid>
                <Grid item xs={6} md={12}>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Incident Location: ${selectedItem.incident_location}`}
                </Typography>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Incident Details: ${selectedItem.incident_detail}`}
                </Typography>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Incident Date: ${selectedItem.incident_date.split('T')[0]}`}
                </Typography>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Incident Time: ${selectedItem.incident_date.split('T')[1]}`}
                </Typography>
                </Grid>
              </Grid>

              </Grid>

              <Grid item xs={12} md={2}>
              <Grid container>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                  Customer Details
                  </Typography>
                </Grid>
                <Grid item xs={6} md={12}>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Name: ${selectedItem.customer_name ? selectedItem.customer_name : "-"}`}
                </Typography>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Phone: ${selectedItem.customer_phone ? selectedItem.customer_phone : "-"}`}
                </Typography>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Email: ${selectedItem.customer_email ? selectedItem.customer_email : "-"}`}
                </Typography>
                </Grid>
              </Grid>


              </Grid>
              <Grid item xs={12} md={2}>
              <Grid container>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                  Created
                  </Typography>
                </Grid>
                <Grid item xs={6} md={12}>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`${new Date(selectedItem.created*1000).getFullYear()}
                -${new Date(selectedItem.created*1000).getMonth()+1}
  -${new Date(selectedItem.created*1000).getDate()} ${new Date(selectedItem.created*1000).getHours()}: ${new Date(selectedItem.created*1000).getMinutes()}`}
                </Typography>
                </Grid>
              </Grid>
              </Grid>

          </Grid>

          {selectedItem.repair_detail_created ? <Grid container style={{ marginTop: 10}}>

          <Grid item xs={12} md={12}>
          <Divider style={{ marginTop: 10, marginBottom: 15 }}/>
            </Grid>
          
              <Grid item xs={12} md={2}>
              
              <Grid container>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                  Action
                  </Typography>
                </Grid>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontSize: 11 }}>
                 Submitted Repair Details
                  </Typography>
                </Grid>
              </Grid>
              

               

              </Grid>
              <Grid item xs={12} md={4} style={{ flexDirection: "row"}}>
                <Grid container>
                  <Grid item xs={6} md={12}>
                    <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                    Attached
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <Grid style={{ display: "flex", flexDirection: "row"}}>
                      <Grid>
                        <Typography align='left' style={{ fontSize: 11 }}>
                        Repair Image
                        </Typography>
                        <Link target='_blank' href={selectedItem.post_repair_img_fullPath}>
                        { selectedItem.post_repair_img_contentType === "application/pdf" ?"PDF Link" : <img height={50} width={40} alt='file' src={selectedItem.post_repair_img_fullPath}/>}
                        </Link>
                      </Grid>
                      
                      <Grid style={{ marginLeft: 20}}>
                        <Typography align='left' style={{ fontSize: 11 }}>
                        Repair Invoice
                        </Typography>
                        <Link target='_blank' href={selectedItem.repair_invoice_img_fullPath}>
                        { selectedItem.repair_invoice_img_contentType === "application/pdf" ?"PDF Link" : <img height={50} width={40} alt='file' src={selectedItem.repair_invoice_img_fullPath}/>}
                        </Link>
                      </Grid>

                      <Grid style={{ marginLeft: 20}}>
                        <Typography align='left' style={{ fontSize: 11 }}>
                        Repair Report
                        </Typography>
                        <Link target='_blank' href={selectedItem.service_repair_report_img_fullPath}>
                        { selectedItem.service_repair_report_img_contentType === "application/pdf" ?"PDF Link" : <img height={50} width={40} alt='file' src={selectedItem.service_repair_report_img_fullPath}/>}
                        </Link>
                      </Grid>
                      </Grid>
                  </Grid>
                </Grid>

              </Grid>


              <Grid item xs={12} md={2}>

              <Grid container>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                  Repair Details
                  </Typography>
                </Grid>
                {selectedItem.repair_amount ? <Grid item xs={6} md={12}>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Repair Amount: USD ${selectedItem.repair_amount}`}
                </Typography>

                <Typography align='left' style={{ fontSize: 11 }}>
                {`Service Center: ${selectedItem.servicecenter.toUpperCase()}`}
                </Typography>
                </Grid> : null}
              </Grid>
              </Grid>

              <Grid item xs={12} md={2}>

              <Grid container>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                  Repair Date
                  </Typography>
                </Grid>
                {selectedItem.repair_date ? <Grid item xs={6} md={12}>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Date: ${selectedItem.repair_date.split('T')[0]}`}
                </Typography>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`Time: ${selectedItem.repair_date.split('T')[1]}`}
                </Typography>
                </Grid> : null}
              </Grid>
              </Grid>
              
              

              <Grid item xs={12} md={2}>

              <Grid container>
                <Grid item xs={6} md={12}>
                  <Typography align='left' style={{ fontWeight: 'bold', fontSize: 14 }}>
                  Created
                  </Typography>
                </Grid>
                <Grid item xs={6} md={12}>
                <Typography align='left' style={{ fontSize: 11 }}>
                {`${new Date(selectedItem.repair_detail_created
*1000).getFullYear()}
                -${new Date(selectedItem.repair_detail_created
                  *1000).getMonth()+1}
  -${new Date(selectedItem.repair_detail_created
    *1000).getDate()} ${new Date(selectedItem.repair_detail_created
      *1000).getHours()}: ${new Date(selectedItem.repair_detail_created
      *1000).getMinutes()}`}
                </Typography>
                </Grid>
              </Grid>
              </Grid>

          </Grid> : null}
          
        </DialogContent>
      </Dialog> : null}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          EW Claim Submission
          </Typography>
          <Stack direction="row" alignItems="end" >
          {userRole === "super_admin" ? <Button variant="contained" style={{ marginRight: 10 }} onClick={downloadExcel}>
              {"Download Excel (Activation Date)"}
            </Button> : null}
            {userRole === "super_admin" ? <Button variant="contained" style={{ marginRight: 10 }} onClick={downloadExcelCreatedDate}>
              {"Download Excel (Created Date)"}
            </Button> : null}

            {userRole === "super_admin" ? <Button variant="contained" style={{ marginRight: 10 }} onClick={downloadExcelAll}>
              {"Download All"}
            </Button> : null}
           {/* } {userRole === "super_admin" ? <Button variant="contained"  href='/dashboard/bulk_upload_device'>
              Bulk Upload
            </Button> : null} */}
           
          </Stack>
          
        </Stack>

        <Card>
          <UserListToolbar 
          handleChangeSelect={handleChangeSelect}
          select={select}
          numSelected={selected.length} 
          filterName={filterName} 
          onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy2}
                  headLabel={TABLE_HEAD}
                  rowCount={row.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                          <Link href='#' onClick={()=> {
                            setSelectedItem(row)
                            setDialogOpen4(true)}} >
                            <Typography  variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                            </Link>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Typography align='left' style={{ fontSize: 14 }}>{`${new Date(row.created*1000).getFullYear()}
                        -${new Date(row.created*1000).getMonth()+1}
                        -${new Date(row.created*1000).getDate()}`}
                          </Typography>
                          <Typography align='left' style={{ fontSize: 14 }}>
                            {`${new Date(row.created*1000).getHours()}: ${new Date(row.created*1000).getMinutes()}`}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{row.purchase_date}</TableCell>

                        <TableCell align="left">{row.status}</TableCell>

                        <TableCell align="left">{row.customer_name}</TableCell>
                        
                        <TableCell align="left">{row.serialno}</TableCell>

                        <TableCell align="left">{row.country}</TableCell>
                         <TableCell align="left">{row.servicecenter}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e)=> handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'}/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            component="div"
            count={row.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

          <MenuItem onClick={handleOnDetails} >
            <Iconify icon={'clarity:details-solid'} sx={{ mr: 2 }} />
            Details
          </MenuItem>

          {userRole === "super_admin" ? <MenuItem onClick={handleOnEdit} >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem> :null}

      </Popover>
    </>
  );
}
