// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Claim',
    path: '/dashboard/search_claim',
    icon: icon('ic_insurance'),
  },
  {
    title: 'SC Submitted Claim',
    path: '/dashboard/sc_submitted_claim',
    icon: icon('submit_claim'),
  },
  {
    title: 'EW Submitted Claim',
    path: '/dashboard/ew_submitted_claim',
    icon: icon('submit_claim'),
  }
];

export default navConfig;
