// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Iphone 13/14/15 Device',
    path: '/dashboard/device',
    icon: icon('ic_device'),
  },
  {
    title: 'Search All Device',
    path: '/dashboard/search_device',
    icon: icon('ic_device'),
  }
];

export default navConfig;
