import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import * as FileSaver from 'file-saver';
import * as React from 'react';
import XLSX from 'sheetjs-style';
import PropTypes from 'prop-types';
import { useTheme, styled, alpha } from '@mui/material/styles';
import moment from 'moment'
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Tooltip,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Grid,
 OutlinedInput, InputAdornment
} from '@mui/material';

import Link from '@mui/material/Link';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import dayjs from 'dayjs';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { collection, getDocs, getFirestore, addDoc,updateDoc , doc, serverTimestamp, onSnapshot, query, orderBy, limitToLast, where } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/device';

import { UserAuth } from '../context/AuthContext'


const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const rtdb = getDatabase(app);


const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Registration ID', alignRight: false },
  { id: 'created', label: 'Registration Date/Time', alignRight: false },
  { id: 'purchase_date', label: 'Purchase Date', alignRight: false },
  { id: 'full_name', label: 'Full Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'imeino', label: 'Imei', alignRight: false },
  { id: 'country', label: 'Country', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy2) {
  if (b[orderBy2] < a[orderBy2]) {
    return -1;
  }
  if (b[orderBy2] > a[orderBy2]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy2) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy2)
    : (a, b) => -descendingComparator(a, b, orderBy2);
}

function applySortFilter(array, comparator, query, select) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    if(select === "imeino"){
      return filter(array, (_user) => _user.imeino.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if(select === "full_name"){
      return filter(array, (_user) => _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    
    if(select === "id"){
      return filter(array, (_user) => _user.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if(select === "email"){
      return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if(select === "serialno"){
      return filter(array, (_user) => _user.serialno.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = '.xlsx';

export default function ProductPage() {

  const { user } = UserAuth()

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy2, setOrderBy2] = useState('created');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [row, setRow] = useState([]);

  const [fileUrl, setFileUrl] = useState(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  const [disabled3, setDisabled3] = useState(false);

  const [status, setStatus] = useState('');
  const [userCountry, setUserCountry] = useState(null);
  
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const [select, setSelect] = useState('imeino');

  const handleChangeSelect = (event) => {
    setSelect(event.target.value);
  };


  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogClose2 = () => {
    setDialogOpen2(false);
  };


  const handleDialogSubmit = () => {

    if(selectedItem.status !== status)
    {
      updateDoc (doc(db, "device-registration-1", selectedItem.id), {
        status: status || null,
      })
    }
    
    setDialogOpen(false);
  };

  useEffect(() => {


    let _user = "";
    if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
      _user = JSON.parse(user.reloadUserInfo.customAttributes);
    }
    setUserCountry(_user.country)

    /* let isMounted = true;

    const doFetch = async () => {
      const dataRef = collection(db, "device-registration-1");
      const q = query(dataRef, orderBy("created", 'asc'), limitToLast(8000));
      const querySnapshot = await getDocs(q);
      const arr = [];
      querySnapshot.forEach((doc) => {

        if(moment(doc.data().purchase_date).isAfter('2023-10-02')){

          if(doc.data().country === _user.country)
          {
            arr.push({
              ...doc.data(),
              id: doc.id,
            });
          }
          
          

        }
        
      });

      if (isMounted) {
        setRow(getUnique(arr,'imeino'));
        setLoading(false);
      }
    };

    doFetch() // start the async work
      .catch((err) => {
        if (!isMounted) return; // unmounted, ignore.
        // TODO: Handle errors in your component
        console.error("failed to fetch data", err);
      });

    return () => {
      isMounted = false;
    }; */

    /* const unsubscribe = onSnapshot(collection(db, "device-registration-1"), (querySnapshot) => {
      const arr = [];
      querySnapshot.forEach((doc) => {

        if(doc.data().country === _user.country)
          {
            arr.push({
              ...doc.data(),
              id: doc.id,
            });
          }
          else if(_user.role === "super_admin"){
            arr.push({
              ...doc.data(),
              id: doc.id,
            });
          }

      });

      setRow(arr)
      setLoading(false)
    });

    return () => {
      unsubscribe()
    }; */
    
  }, [])

  const handleSearch = async () => {
    setOpenDetails(true)
    setDialogLoading(true);
    setSelectedItem(null)
    const arr = [];
    get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
      if (snapshot.exists()) {

        snapshot.forEach((doc) => {

          if(doc.val().country === userCountry || !userCountry)
          {
            if(select === "imeino"){
              if(Number(doc.val().imeino) ===  Number(filterName))
              {
                arr.push({
                  ...doc.val(),
                  id: doc.key,
                });
              }
             
            }

            if(select === "serialno"){
              if(doc.val().serialno ===  filterName)
              {
                arr.push({
                  ...doc.val(),
                  id: doc.key,
                });
              }
             
            }

            
          }

        })

        if(arr.length > 0){
          setSelectedItem(arr[0])
        }
        else{
          setSelectedItem(null)
        }

        setDialogLoading(false);
      }
    })

    /* const dataRef = collection(db, "device-registration");

      if(select === "imeino"){
        const q = query(dataRef, where("imeino", "==", filterName));
        const querySnapshot = await getDocs(q);
        const arr = [];
        querySnapshot.forEach((doc) => {

          if(doc.data().country === userCountry || !userCountry)
          {
            arr.push({
              ...doc.data(),
              id: doc.id,
            });
          }

        });

        if(arr.length > 0){
          setSelectedItem(arr[0])
        }
        else{

          const dataRef1 = collection(db, "device-registration-1");

          const q1 = query(dataRef1, where("imeino", "==", filterName));
          const querySnapshot1 = await getDocs(q1);
          const arr1 = [];
          querySnapshot1.forEach((doc) => {

            if(doc.data().country === userCountry || !userCountry)
            {
              arr1.push({
                ...doc.data(),
                id: doc.id,
              });
            }

          });

          if(arr1.length > 0){
            setSelectedItem(arr1[0])
          }
          else{
            setSelectedItem(null)
          }

          setDialogLoading(false);
          
        }
      }

      if(select === "serialno"){
        const q = query(dataRef, where("serialno", "==", filterName));
        const querySnapshot = await getDocs(q);
        const arr = [];
        querySnapshot.forEach((doc) => {

          if(doc.data().country === userCountry || !userCountry)
          {
            arr.push({
              ...doc.data(),
              id: doc.id,
            });
          }

        });

        if(arr.length > 0){
          setSelectedItem(arr[0])
        }
        else{
          const dataRef1 = collection(db, "device-registration-1");

          const q1 = query(dataRef1, where("serialno", "==", filterName));
          const querySnapshot1 = await getDocs(q1);
          const arr1 = [];
          querySnapshot1.forEach((doc) => {

            if(doc.data().country === userCountry || !userCountry)
            {
              arr1.push({
                ...doc.data(),
                id: doc.id,
              });
            }

          });

          if(arr1.length > 0){
            setSelectedItem(arr1[0])
          }
          else{
            setSelectedItem(null)
          }

          setDialogLoading(false);
        }
      } */
      

      
      

      
  }

  const getUnique = (arr, index) => {

    const unique = arr
         .map(e => e[index])
  
         // store the keys of the unique objects
         .map((e, i, final) => final.indexOf(e) === i && i)
    
         // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);      
  
     return unique;
  }

  const handleSubmitDownload = async () => {

    setDialogLoading(true);

    const imeiRef = collection(db, "imei");
    const q = query(
      imeiRef
    );
    const documents = await getDocs(q);
    const tempImei = [];
    if (!documents.empty) {
      
      documents.forEach((document) => {
        tempImei.push({
          id: document.id,
          ...document.data(),
        });
      });
      
    }

    const productRef = collection(db, "product");
    const q2 = query(
      productRef
    );
    const documents2 = await getDocs(q2);
    const tempProduct = [];
    if (!documents2.empty) {
      
      documents2.forEach((document) => {
        tempProduct.push({
          id: document.id,
          ...document.data(),
        });
      });
      
    }

    tempImei.forEach(val => {
      const currentIndex = tempProduct.map(value => value.partno.replace(/\s/g,'').toLowerCase()).indexOf(val.partno.replace(/\s/g,'').toLowerCase());

      if(currentIndex !== -1){
        val.capacity = tempProduct[currentIndex].capacity;
        val.color = tempProduct[currentIndex].color;
        val.product_name = tempProduct[currentIndex].name;
      }
    })

    const _newRow = []
    row.forEach(val => {

      if(moment(val.purchase_date).isSameOrAfter(startDate.format('YYYY-MM-DD')) 
      && moment(val.purchase_date).isSameOrBefore(endDate.format('YYYY-MM-DD')))
      {
        const currentIndex = tempImei.map(value => value.imeino.toString().replace(/\s/g,'')).indexOf(val.imeino.toString().replace(/\s/g,''));

        const _item = {};
        _item["Registration ID"] = val.id;
        _item["Registration Date/Time"] = `${new Date(val.created*1000).getFullYear()}-${new Date(val.created*1000).getMonth()+1}-${new Date(val.created*1000).getDate()} (${new Date(val.created*1000).getHours()}: ${new Date(val.created*1000).getMinutes()})`;
        _item["Invoice Date"] = val.purchase_date;
        _item.Owner = val.full_name;
        _item["Email Address"] = val.email;
        
        _item.IMEI = val.imeino;
        _item["Serial No."] = val.serialno;
        _item.Country = val.country;
        _item.City = val.city;
        _item.Warranty = "Screen Replacement Warranty,Extended Warranty";
 
        if(currentIndex !== -1){
         _item["Product Name"] = tempImei[currentIndex].product_name;
         _item.Capacity = tempImei[currentIndex].capacity;
         _item.Color = tempImei[currentIndex].color;
         _item["Serial No."] = tempImei[currentIndex].serialno;
        }
 
        _newRow.push(_item)
      }
      
    })


    const ws = XLSX.utils.json_to_sheet(_newRow);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `device_registrations_data${fileExtension}`); 

    setDialogOpen2(false);
    setDialogLoading(false);
  }

  const downloadExcel = async () => {
    setDialogOpen2(true);
  }

  const handleOnDetails = () => {
    setOpenDetails(true);
    setOpen(false)
  }

  const handleOnEdit = () => {
    setDialogOpen(true);
    setOpen(false)
  }

  const handleOpenMenu = (event, row) => {
    setSelectedItem(row)
    setOpen(event.currentTarget);
    setStatus(row.status)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy2 === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy2(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = row.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value.replace(/\s/g,''));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - row.length) : 0;

  const filteredUsers = applySortFilter(row, getComparator(order, orderBy2), filterName, select);

  const isNotFound = !filteredUsers.length && !!filterName;

  if(loading){
    return (
      
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }

  const renderCountry = (country) => {
    let _country = "";
    if(country === "srilanka"){
      _country = "Sri Lanka";
    }
    else if(country === "brunei"){
      _country = "Brunei";
    }
    else if(country === "nepal"){
      _country = "Nepal";
    }
    else if(country === "mongolia"){
      _country = "Mongolia";
    }
    return _country;
  }

  const renderStatus = (status) => {
    let _status = "";
    if(status === "pending_verification"){
      _status = "Pending Verification";
    }
    else if(status === "verified"){
      _status = "Verified";
    }

    return _status;
  }

  const renderFilePath = (path) => {
    
    const printUrl = async (_path) => {
      const storage = getStorage(app);
      const _url = await getDownloadURL(ref(storage, _path))
        .then((url) => {
          return url;
        })
        .catch((error) => {
          // Handle any errors
        });
        setFileUrl(_url)
    };
    
    printUrl(path);

    return fileUrl;
  }

  const handleDownloadProduct = () => {

    setDisabled1(true)
    const arr = [];
    get(child(dbref(rtdb), `product`)).then((snapshot) => {
      if (snapshot.exists()) {

        snapshot.forEach((doc) => {
          arr.push({
            ...doc.val(),
            id: doc.key,
          });
        })
        const _newRow = []
        arr.forEach(val => {
          const _item = {};
            _item.id = val.id;
            _item.Capacity = val.capacity;
            _item.Category = val.category;
            _item.Color = val.color;
            _item.Name = val.name;
            _item["Part No"] = val.partno;
            _item.Status = val.status;
            _newRow.push(_item)
        })
    
        const ws = XLSX.utils.json_to_sheet(_newRow);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `product_list${fileExtension}`);
        setDisabled1(false)

      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  const handleDownloadIMEI = () => {
    setDisabled2(true)
    const arr = [];
    get(child(dbref(rtdb), `imei`)).then((snapshot) => {
      if (snapshot.exists()) {

        snapshot.forEach((doc) => {
          arr.push({
            ...doc.val(),
            id: doc.key,
          });
        })
        const tempProduct = [];
        get(child(dbref(rtdb), `product`)).then((snapshot1) => {
          if (snapshot1.exists()) {
    
            snapshot1.forEach((doc1) => {
    
              tempProduct.push({
                ...doc1.val(),
                id: doc1.key,
              });
            })
            const _newRow = []
            arr.forEach(val => {
          
              const currentIndex = tempProduct.map(value => value.partno.replace(/\s/g,'').toUpperCase()).indexOf(val.partno.replace(/\s/g,'').toUpperCase());
    
              if(currentIndex !== -1){
                val.capacity = tempProduct[currentIndex].capacity;
                val.color = tempProduct[currentIndex].color;
                val.product_name = tempProduct[currentIndex].name;
              }
    
              const _item = {};
              _item.id = val.id;
              _item["Shipment Date"] = val.shipmentdate;
              _item["Serial No"] = val.serialno;
              _item["Part No"] = val.partno;
              _item["IMEI No"] = val.imeino;
              _item["IMEI No"] = val.imeino;
    
              _item["Product Name"] = val.product_name;
              _item.Capacity = val.capacity;
              _item.Color = val.color;
              _item.country = val.country;
              _newRow.push(_item)
            })
    
            const ws = XLSX.utils.json_to_sheet(_newRow);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, `imei_list${fileExtension}`);
            setDisabled2(false)
          }
        })


      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  const handleDownloadRegisteredDevice = () => {
    setDisabled3(true)
    const arr = [];
    get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
      if (snapshot.exists()) {

        snapshot.forEach((doc) => {
          arr.push({
            ...doc.val(),
            id: doc.key,
          });
        })

        const tempImei = [];
        get(child(dbref(rtdb), `imei`)).then((snapshot) => {
        if (snapshot.exists()) {

          snapshot.forEach((doc) => {
            tempImei.push({
              ...doc.val(),
              id: doc.key,
            });
          })

          const tempProduct = [];
          get(child(dbref(rtdb), `product`)).then((snapshot1) => {
            if (snapshot1.exists()) {

              snapshot1.forEach((doc1) => {

                tempProduct.push({
                  ...doc1.val(),
                  id: doc1.key,
                });
              })

              tempImei.forEach(val => {
            
                const currentIndex = tempProduct.map(value => value.partno.replace(/\s/g,'').toUpperCase()).indexOf(val.partno.replace(/\s/g,'').toUpperCase());
          
                
                if(currentIndex !== -1){
                  val.capacity = tempProduct[currentIndex].capacity;
                  val.color = tempProduct[currentIndex].color;
                  val.product_name = tempProduct[currentIndex].name;
                }
              })
              let count = 0;
              const _newRow = []
              arr.forEach((val, index) => {
                setTimeout(async ()=> {

                  const currentIndex = tempImei.map(value => value.imeino.toString().replace(/\s/g,'')).indexOf(val.imeino.toString().replace(/\s/g,''));
          
                  const _item = {};
                  _item["Registration ID"] = val.id;
                  _item["Registration Date/Time"] = `${new Date(val.created*1000).getFullYear()}-${new Date(val.created*1000).getMonth()+1}-${new Date(val.created*1000).getDate()} (${new Date(val.created*1000).getHours()}: ${new Date(val.created*1000).getMinutes()})`;
                  _item["Invoice Date"] = val.purchase_date;
                  _item.Owner = val.full_name;
                  _item["Email Address"] = val.email;
                  
                  _item.IMEI = val.imeino;
                  _item["Serial No."] = val.serialno;
                  _item.Country = val.country;
                  _item.City = val.city;
                  _item.Warranty = "Screen Replacement Warranty,Extended Warranty";
           
                  if(currentIndex !== -1){
                   _item["Product Name"] = tempImei[currentIndex].product_name;
                   _item.Capacity = tempImei[currentIndex].capacity;
                   _item.Color = tempImei[currentIndex].color;
                   _item["Serial No."] = tempImei[currentIndex].serialno;
                  }
           
                  _newRow.push(_item)

                  count += 1;
                  console.log(count)
                  if(arr.length === count)
                  {
                    const ws = XLSX.utils.json_to_sheet(_newRow);
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data = new Blob([excelBuffer], { type: fileType });
                    FileSaver.saveAs(data, `device_registrations_data${fileExtension}`); 
                    setDisabled3(false)
                  }

                }, index * 1);
                
              })
          
          
             

            }
          })
         
         

        }

      })

      }
    })
    
  }
  

  return (
    <>
      <Helmet>
        <title> Device | Genxt Services </title>
      </Helmet>

      <Dialog
        open={openDetails}
        onClose={handleCloseDetails}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Registration Details"}
        </DialogTitle>
        {dialogLoading ? <DialogContent>
          <CircularProgress color="inherit" />
          </DialogContent> : <DialogContent>
          {selectedItem ? <DialogContentText id="alert-dialog-description">
              {selectedItem ? <Grid style={{ width: "100%", display: "flex",
                flexDirection: "row" }} ><Grid style={{ width: "100%", display: "flex",
                flexDirection: "column", paddingRight: 30 }}>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Register ID
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem && selectedItem.id ? selectedItem.id : "-"}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Register Date/Time
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 14 }}>{`${new Date(selectedItem.created*1000).getFullYear()}
                -${new Date(selectedItem.created*1000).getMonth()+1}
                -${new Date(selectedItem.created*1000).getDate()} ${new Date(selectedItem.created*1000).getHours()}: ${new Date(selectedItem.created*1000).getMinutes()}`}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Purchase Date
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.purchase_date}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Full Name
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.full_name}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Email
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.email}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  IMEI
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.imeino}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Country
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {renderCountry(selectedItem.country)}
                  </Typography>


              </Grid>
              <Grid style={{ width: "100%", display: "flex",
                flexDirection: "column" }}>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  City
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.city}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Home Address
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 14 }}>
                  {selectedItem.home_address ? selectedItem.home_address : "-"}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  NIC
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.nic ? selectedItem.nic : "-"}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Passport
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.passport ? selectedItem.passport : "-"}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Serial No.
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.serialno}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Status
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {renderStatus(selectedItem.status)}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  File
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.file_fullPath ? <Link target='_blank' href={renderFilePath(selectedItem.file_fullPath)}>Link</Link> : "none"}
                  </Typography>


              </Grid>
              </Grid> : null }
          </DialogContentText> : <Grid>
            <Typography align='left' style={{ fontSize: 14, fontWeight: 'bold', marginTop: 15 }}>
                  Not Found
            </Typography>
            <Typography align='left' style={{ fontSize: 12, marginTop: 15 }}>
                  {` No results found for "${filterName}".
Try checking for typos or using complete words.`}
            </Typography>
           
          </Grid>}
        </DialogContent> }
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen2}
        onClose={handleDialogClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Download Excel"}
        </DialogTitle>
        {dialogLoading ? <DialogContent>
          <CircularProgress color="inherit" />
          </DialogContent> : <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select the purchase date range
          </DialogContentText>
          <Grid style={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="End Date" 
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>
          </Grid>
        </DialogContent>}
        {dialogLoading ? null : <DialogActions>
          <Button onClick={handleDialogClose2}>Cancel</Button>
          <Button onClick={handleSubmitDownload} autoFocus>
            Download
          </Button>
        </DialogActions> }
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit"}
        </DialogTitle>
        <DialogContent>
          <FormControl style={{ marginTop: 20 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="Status"
              id="Status"
              value={status}
              label="Status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={"pending_verification"}>Pending Verification</MenuItem>
              <MenuItem value={"verified"}>Verified</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={handleDialogSubmit} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Grid style={{ display: "flex", flexDirection: 'column', padding: 20, alignItems: "center"}}>

      <Button disableElevation disabled={disabled1}
       onClick={handleDownloadProduct} style={{ marginTop: 20 }} variant="contained">Download All Product Details</Button>
          
      <Button disableElevation disabled={disabled2}
       onClick={handleDownloadIMEI} style={{ marginTop: 20 }} variant="contained">Download All IMEIs</Button>

<Button disableElevation disabled={disabled3}
       onClick={handleDownloadRegisteredDevice} style={{ marginTop: 20 }} variant="contained">Download All Registered Device</Button>
         
        </Grid>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

          <MenuItem onClick={handleOnDetails} >
            <Iconify icon={'clarity:details-solid'} sx={{ mr: 2 }} />
            Details
          </MenuItem>

          <MenuItem onClick={handleOnEdit} >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

      </Popover>
    </>
  );
}
