import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom'
import { getDatabase, ref as dbref, runTransaction, set, child, get } from "firebase/database";
import { initializeApp } from "firebase/app";
import moment from 'moment'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { UserAuth } from '../context/AuthContext'



// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';



const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);



const rtdb = getDatabase(app);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [loading2, setLoading2] = React.useState(true);
  const [row, setRow] = React.useState([]);
  const [dateRegCambodia, setDateRegCambodia] = React.useState([]);
  const [totalCambodia, setTotalCambodia] = React.useState(0);
  const [totalSERIALNO, setTotalSERIALNO] = React.useState(0);
  const [totalPurchasedSERIALNO, setTotalPurchasedSERIALNO] = React.useState(0);

  const [totalSubmitted, setTotalSubmitted] = React.useState(0);
  const [totalPendingApproval, setPendingApproval] = React.useState(0);
  const [totalApproved, setTotalApproved] = React.useState(0);
  const [totalClosed, setTotalClosed] = React.useState(0);
  const [totalCancelled, setTotalCancelled] = React.useState(0);
  const [totalRejected, setTotalRejected] = React.useState(0);
  const [totalSubmitRepairDetail, setTotalSubmitRepairDetail] = React.useState(0);
  const [totalSeekVerification, setTotalSeekVerification] = React.useState(0);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { user } = UserAuth()

  useEffect(() => {

    let _user = "";
    if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
      _user = JSON.parse(user.reloadUserInfo.customAttributes);
    }

    if(value === 0)
    {

      get(child(dbref(rtdb), `sc_claimed`)).then((snapshot) => {

        const arr = [];
        if (snapshot.exists()) {

          snapshot.forEach((doc) => {

            arr.push({
              ...doc.val()
            });
            
          }) 

          let _totalApproved = 0;
          let _totalPendingApproval = 0;
          let _totalClosed = 0;
          let _totalCancelled = 0;
          let _totalRejected = 0;
          let _totalSubmitRepairDetail = 0;
          let _totalSeekVerification = 0;

          const dateArrayRegCambodia = {};
          
          for (let i = 0; i < 11; i += 1) {

            const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
            const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
            
            dateArrayRegCambodia[i] = { date: _priorDate, total :0 }
          } 

          arr.forEach((val) => {

            if(val.status === "approved"){
              _totalApproved += 1;
            }

            if(val.status === "submit_for_approval"){
              _totalPendingApproval += 1;
            }

            if(val.status === "closed"){
              _totalClosed += 1;
            }

            if(val.status === "cancelled"){
              _totalCancelled += 1;
            }

            if(val.status === "rejected"){
              _totalRejected += 1;
            }

            if(val.status === "submit_for_repair_detail"){
              _totalSubmitRepairDetail += 1;
            }

            if(val.status === "seek_verification"){
              _totalSeekVerification += 1;
            }


            const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
            
            for (let i = 0; i < 11; i += 1) {
              
              const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
              
              const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`

              if(_priorDate === _RegDate)
              {
                dateArrayRegCambodia[i].total += 1;
              }
              
            }
            
          }) 

         
          setDateRegCambodia(dateArrayRegCambodia)
          setTotalSeekVerification(_totalSeekVerification)
          setTotalSubmitRepairDetail(_totalSubmitRepairDetail)
          setTotalRejected(_totalRejected)
          setTotalCancelled(_totalCancelled)
          setTotalClosed(_totalClosed)
          setPendingApproval(_totalPendingApproval)
          setTotalApproved(_totalApproved);
          setTotalSubmitted(arr.length)
          
          setLoading(false)
        } else {

          const dateArrayRegCambodia = {};
          
          for (let i = 0; i < 11; i += 1) {

            const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
            const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
            
            dateArrayRegCambodia[i] = { date: _priorDate, total :0 }
          } 


          setDateRegCambodia(dateArrayRegCambodia)
          setLoading(false)
        }
      }).catch((error) => {
        console.error(error);
      });

      setRow([]);
    }

  }, [value])

  return (
    <>
      <Helmet>
      <title> Dashboard | Gnext </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="Apple Label">
              <Tab label="iPhone 16" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {loading ? 
            <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>
              <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
              flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress color="inherit" />
                </Box>
              </Grid>
            </Grid>
          :
            <Grid container spacing={3}>

              <Grid item xs={12} sm={6} md={4}>
                <AppWidgetSummary title="Total Claim Submitted" total={totalSubmitted.toString()} color="error" icon={'twemoji:bookmark-tabs'} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <AppWidgetSummary title="Total Claim Pending Approval" total={totalPendingApproval.toString()} color="error" icon={'twemoji:bookmark-tabs'} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <AppWidgetSummary title="Total Claim Approved" total={totalApproved.toString()} color="error" icon={'twemoji:bookmark-tabs'} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <AppWidgetSummary title="Total Claim Closed" total={totalClosed.toString()} color="error" icon={'twemoji:bookmark-tabs'} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <AppWidgetSummary title="Total Claim Amount Paid" total={totalClosed.toString()} color="error" icon={'twemoji:bookmark-tabs'} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <AppWidgetSummary title="Total Claim Amount Pending" total={totalSubmitRepairDetail.toString()} color="error" icon={'twemoji:bookmark-tabs'} />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <AppWebsiteVisits
                  title="Claim Registration Stats"
                  chartLabels={[
                    dateRegCambodia[0].date,
                    dateRegCambodia[1].date,
                    dateRegCambodia[2].date,
                    dateRegCambodia[3].date,
                    dateRegCambodia[4].date,
                    dateRegCambodia[5].date,
                    dateRegCambodia[6].date,
                    dateRegCambodia[7].date,
                    dateRegCambodia[8].date,
                    dateRegCambodia[9].date,
                    dateRegCambodia[10].date,
                  ]}
                  chartData={[
                    {
                      name: 'Cambodia',
                      type: 'area',
                      fill: 'gradient',
                      data: [dateRegCambodia[0].total, 
                      dateRegCambodia[1].total, 
                      dateRegCambodia[2].total, 
                      dateRegCambodia[3].total, 
                      dateRegCambodia[4].total, 
                      dateRegCambodia[5].total, 
                      dateRegCambodia[6].total, 
                      dateRegCambodia[7].total, 
                      dateRegCambodia[8].total, 
                      dateRegCambodia[9].total, 
                      dateRegCambodia[10].total],
                    }
                  ]}
                />
              </Grid>

            </Grid>}
          </CustomTabPanel>
        </Box>

        
      </Container>
    </>
  );
}
