import { useState, useEffect } from 'react';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography, Grid } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LoadingButton } from '@mui/lab';
// components
import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------

const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const auth = getAuth(app);

export default function LoginForm() {
  
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {

  }, [])

  const handleClick = () => {
    let emailPass = false;
    
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(email && email.length > 0 && emailRegex.test(email)){
      setEmailError(false)
      emailPass = true;
    }
    else
    {
      setEmailError(true)
    }

    if(emailPass === true){

      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
        .then(() => {
          navigate('/login')
        })
        .catch((error) => {
          setEmailError(true)
        });
    }

    
  };

  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  }


  if(loading){
    return (
      
      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField style={{ marginBottom: 20 }} autoComplete="off" name="email" label="Email address" error={emailError} value={email} onChange={handleOnChangeEmail}/>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Send
      </LoadingButton>
      <Link style={{ marginTop: 20 }}  href="/login" color="inherit">
        {'Back to Login'}
      </Link>
    </>
  );
}
