import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, serverTimestamp, getDocs  } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// TODO remove, this demo shouldn't need to reset the theme.
export default function NewDeviceRegistrationPage() {

  const [warranty, setWarranty] = React.useState('extended_warranty');
  const [country, setCountry] = React.useState('SG');
  
  const [imeiError, setImeiError] = React.useState(false);
  const [fullNameError, setFullNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [serialNoError, setSerialNoError] = React.useState(false);
  const [cityError, setCityError] = React.useState(false);

  const [imeiList, setImeiList] = React.useState([]);
  const [imei, setImei] = React.useState(null);

  const [rGdate, setRGdate] = React.useState(dayjs());
  const [loading, setLoading] = React.useState(true);

  const options = React.useMemo(() => countryList().getData(), [])

  const navigate = useNavigate();

  React.useEffect(() => {

    let isMounted = true;
    
    const doFetch = async () => {
      const querySnapshot = await getDocs(collection(db, "imei"));
      const arr = [];
      querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id,
        });
        if (isMounted) {
          setImeiList(arr);
          setLoading(false);
        }
      });
    };

    doFetch() // start the async work
      .catch((err) => {
        if (!isMounted) return; // unmounted, ignore.
        // TODO: Handle errors in your component
        console.error("failed to fetch data", err);
      });

    return () => {
      isMounted = false;
    };
    
  }, [])

  const handleChangeImei = (event) => {
    setImei(event.target.value);
  };


  const handleChangeWarranty = (event) => {
    setWarranty(event.target.value);
  };

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let imeiPass = false;
    let fullNamePass = false;
    let serialNoPass = false;
    let emailPass = false;
    let cityPass = false;

    const data = new FormData(event.currentTarget);

    if(imei){
      setImeiError(false)
      imeiPass = true;
    }
    else
    {
      setImeiError(true)
    }

    if(data.get('fullName').length > 4){
      setFullNameError(false)
      fullNamePass = true;
    }
    else
    {
      setFullNameError(true)
    }

    if(data.get('serial_no').length > 4){
      setSerialNoError(false)
      serialNoPass = true;
    }
    else
    {
      setSerialNoError(true)
    }

    if(data.get('city').length > 4){
      setCityError(false)
      cityPass = true;
    }
    else
    {
      setCityError(true)
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(data.get('email').length > 0 && emailRegex.test(data.get('email'))){
      setEmailError(false)
      emailPass = true;
    }
    else
    {
      setEmailError(true)
    }

    if(imeiPass === true
      && fullNamePass === true
      && serialNoPass === true
      && emailPass === true
      && cityPass === true
    )
    {
      const currentIndex = imeiList.map(val=>val.id).indexOf(imei)

        setLoading(true);
        await addDoc(collection(db, "device_registration"), {
          registered_date: rGdate.$d.toISOString() || null,
          invoice_date: currentIndex !== -1 ? imeiList[currentIndex].invoice_date : null,
          full_name: data.get('fullName') || null,
          email: data.get('email') || null,
          product_name: currentIndex !== -1 ? imeiList[currentIndex].product_name : null,
          imei_no: currentIndex !== -1 ? imeiList[currentIndex].imei_no : null,
          serial_no: data.get('serial_no') || null,
          country: country || null,
          city:  data.get('city') || null,
          warranty: warranty || null,
          created: serverTimestamp(),
        }).then(()=>{
          setLoading(false);
          navigate('/dashboard/device')
        }).catch(()=>{
          setLoading(false);
        });
    }


  };

  if(loading){
    return (
      
      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column"  }}>
        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
        flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }


  return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <DevicesOtherIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            New Device Registration 
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl error={imeiError} required  style={{ margin: 0, width: "100%" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">IMEI</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Product"
                    value={imei}
                    onChange={handleChangeImei}
                  >
                    {imeiList.map(val=>{
                      return(
                        <MenuItem value={val.id}>{val.imei_no}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  name="serial_no"
                  required
                  error={serialNoError}
                  fullWidth
                  id="serial_no"
                  label="Serial No"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  name="fullName"
                  required
                  error={fullNameError}
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  error={emailError}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Warranty</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={warranty}
                    label="Warranty"
                    onChange={handleChangeWarranty}
                  >
                    <MenuItem value={"extended_warranty"}>Extended Warranty</MenuItem>
                    <MenuItem value={"screen_replacement"}>Screen Replacement</MenuItem>
                    <MenuItem value={"ew_sc"}>EW & SR</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Country"
                    value={country}
                    onChange={handleChangeCountry}
                  >
                    {options.map(val=>{
                      return(
                        <MenuItem value={val.value}>{val.label}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  name="city"
                  required
                  error={cityError}
                  fullWidth
                  id="city"
                  label="City"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <LocalizationProvider sx={{ width: "100%"}} dateAdapter={AdapterDayjs}>
                  <DateTimePicker 
                   label="Registration Date/Time"
                   value={rGdate}
                   onChange={(newDate) => setRGdate(newDate)}
                   sx={{ width: "100%"}}  />
                </LocalizationProvider>
              </Grid>
              
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add Device Registration
            </Button>

          </Box>
        </Box>

      </Container>
  );
}