import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom'
import { getDatabase, ref as dbref, runTransaction, set, child, get } from "firebase/database";
import { initializeApp } from "firebase/app";
import moment from 'moment'

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { UserAuth } from '../context/AuthContext'



// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';



const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);



const rtdb = getDatabase(app);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [loading2, setLoading2] = React.useState(true);
  const [row, setRow] = React.useState([]);
  const [dateRegMyanmar, setDateRegMyanmar] = React.useState([]);
  const [dateRegSrilanka, setDateRegSrilanka] = React.useState([]);
  const [dateRegBrunei, setDateRegBrunei] = React.useState([]);
  const [dateRegNepal, setDateRegNepal] = React.useState([]);
  const [dateRegMongolia, setDateRegMongolia] = React.useState([]);
  const [totalMyanmar, setTotalMyanmar] = React.useState(0);
  const [totalSrilanka, setTotalSrilanka] = React.useState(0);
  const [totalBrunei, setTotalBrunei] = React.useState(0);
  const [totalNepal, setTotalNepal] = React.useState(0);
  const [totalMongolia, setTotalMongolia] = React.useState(0);
  const [totalIMEI, setTotalIMEI] = React.useState(0);
  const [totalEmptyPartNo, setTotalEmptyPartNo] = React.useState(0);
  const [totalPurchasedIMEI, setTotalPurchasedIMEI] = React.useState(0);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { user } = UserAuth()

  useEffect(() => {

    let _user = "";
    if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
      _user = JSON.parse(user.reloadUserInfo.customAttributes);
    }

    

    
    if(value === 0)
    {

      const arr = [];
      const arr2 = [];
      const arr3 = [];
      const arr4 = [];
      const dateArrayRegSrilanka = {}
      const dateArrayRegBrunei = {}
      const dateArrayRegNepal = {}
      const dateArrayRegMongolia = {}
      let _totalSrilanka = 0;
      let _totalBrunei = 0;
      let _totalNepal = 0;
      let _totalMongolia = 0;

      get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
        if (snapshot.exists()) {

          snapshot.forEach((doc) => {

            if(doc.val().country === _user.country)
            {
              arr.push({
                ...doc.val(),
                id: doc.key,
              });
            }
            else if(_user.role === "super_admin"){
              arr.push({
                ...doc.val(),
                id: doc.key,
              });
            }
            
          }) 

          get(child(dbref(rtdb), `imei`)).then((snapshot2) => {
            if (snapshot2.exists()) {
    
              
               snapshot2.forEach((doc) => {
                arr2.push({
                  ...doc.val(),
                  id: doc.key,
                });
              }) 
    
              
    
            } else {
              console.log("No data available");
            }

            

              get(child(dbref(rtdb), `product`)).then((snapshot4) => {
                if (snapshot4.exists()) {
                  snapshot4.forEach((doc) => {

                    const str = doc.val().name.trim().replace(/\s/g, '').toLowerCase();
                    console.log(str)
                    if(str.indexOf("iphone15") !== -1)
                    {
                      arr4.push({
                        ...doc.val(),
                        id: doc.key,
                      });
                    }
                    
                    
                  })

                  const _totalImei = [];
                  const _totalEmptyImei = [];
        
                  arr2.forEach(val=>{
                    const currentindex = arr4.map(val=> val.partno).indexOf(val.partno.replace(/\s/g, ''));
                    if(currentindex !== -1){
                      _totalImei.push(_totalImei)
                    }

                    if(!val.partno || val.partno === ""){
                      _totalEmptyImei.push(_totalImei)
                    }
                  })
                  

                  get(child(dbref(rtdb), `purchasedimei`)).then((snapshot3) => {
                    if (snapshot3.exists()) {
              
                      snapshot3.forEach((doc) => {
                        arr3.push({
                          ...doc.val(),
                          id: doc.key,
                        });
                      })
              
                      let _totalPurchaseImei = 0;
                      snapshot3.forEach((doc) => {
                        _totalPurchaseImei += Number(doc.val().quantity);
                      })
              
                      setTotalPurchasedIMEI(_totalPurchaseImei);
                      setTotalIMEI(_totalImei.length);
                      setTotalEmptyPartNo(_totalEmptyImei.length)

                      setLoading(false);
              
                    } else {
                      console.log("No data available");
                      setTotalPurchasedIMEI(0);
                      setTotalIMEI(_totalImei.length);
                      setTotalEmptyPartNo(_totalEmptyImei.length)

                      setLoading(false);
                    }
                  }).catch((error) => {
                    console.error(error);
                  }); 

                } else {
                  console.log("No data available");
                  setTotalIMEI(0);
                }
              }).catch((error) => {
                console.error(error);
              }); 

            }).catch((error) => {
              console.error(error);
            }); 
          

        // const _arr = getUnique(arr,'imeino');
        setRow(arr);
        
        
         for (let i = 0; i < 11; i += 1) {

          const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
              
          const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
          
          dateArrayRegSrilanka[i] = { date: _priorDate, total :0 }
          dateArrayRegBrunei[i] = { date: _priorDate, total :0 }
          dateArrayRegNepal[i] = { date: _priorDate, total :0 }
          dateArrayRegMongolia[i] = { date: _priorDate, total :0 }
        } 

        arr.forEach((val) => {
          
          if(moment(val.purchase_date).isAfter('2023-10-02')){

            if(val.country.toLowerCase() === "srilanka"){

              
              _totalSrilanka += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegSrilanka[i].total += 1;
                }
                
              }
  
            }
            if(val.country.toLowerCase() === "brunei"){

              // Set the value of 'NYC'
              _totalBrunei += 1;
             
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
  
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                
                if(_priorDate === _RegDate)
                {
                  dateArrayRegBrunei[i].total += 1;
                }
                
              }
  
            }
            if(val.country.toLowerCase() === "nepal"){

              
              _totalNepal += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegNepal[i].total += 1;
                }
                
              }
            }
            if(val.country.toLowerCase() === "mongolia"){

              _totalMongolia += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegMongolia[i].total += 1;
                }
                
              }
            }

          }
         
        }) 


        setDateRegSrilanka(dateArrayRegSrilanka)
        setDateRegBrunei(dateArrayRegBrunei)
        setDateRegNepal(dateArrayRegNepal)
        setDateRegMongolia(dateArrayRegMongolia) 
        setTotalSrilanka(_totalSrilanka)
        setTotalBrunei(_totalBrunei)
        setTotalNepal(_totalNepal)
        setTotalMongolia(_totalMongolia)
        
        

        } else {
          setRow(arr);
          setLoading(false);
        }
      }).catch((error) => {
        console.error(error);
      });
    }

    if(value === 2)
    {
  
        const arr = [];
        const arr2 = [];
        const arr3 = [];
        const arr4 = [];
        const dateArrayRegMyanmar = {}
        let _totalMyanmar = 0;
  
        get(child(dbref(rtdb), `mac-device-registration`)).then((snapshot) => {
          if (snapshot.exists()) {
  
            snapshot.forEach((doc) => {
  
              if(doc.val().country === _user.country)
              {
                arr.push({
                  ...doc.val(),
                  id: doc.key,
                });
              }
              else if(_user.role === "super_admin"){
                arr.push({
                  ...doc.val(),
                  id: doc.key,
                });
              }
              
            }) 
  
            get(child(dbref(rtdb), `mac-serial-number`)).then((snapshot2) => {
              if (snapshot2.exists()) {
      
                
                 snapshot2.forEach((doc) => {
                  arr2.push({
                    ...doc.val(),
                    id: doc.key,
                  });
                }) 
      
                
      
              } else {
                console.log("No data available");
              }
  
              
  
                get(child(dbref(rtdb), `product`)).then((snapshot4) => {
                  if (snapshot4.exists()) {
                    snapshot4.forEach((doc) => {
  
                      const str = doc.val().name.trim().replace(/\s/g, '').toLowerCase();
                      console.log(str)
                      if(str.indexOf("iphone15") !== -1)
                      {
                        arr4.push({
                          ...doc.val(),
                          id: doc.key,
                        });
                      }
                      
                      
                    })
  
                    const _totalImei = [];
                    const _totalEmptyImei = [];
          
                    arr2.forEach(val=>{
                      const currentindex = arr4.map(val=> val.partno).indexOf(val.partno.replace(/\s/g, ''));
                      if(currentindex !== -1){
                        _totalImei.push(_totalImei)
                      }
  
                      if(!val.partno || val.partno === ""){
                        _totalEmptyImei.push(_totalImei)
                      }
                    })
                    
  
                    get(child(dbref(rtdb), `macpurchasedimei`)).then((snapshot3) => {
                      if (snapshot3.exists()) {
                
                        snapshot3.forEach((doc) => {
                          arr3.push({
                            ...doc.val(),
                            id: doc.key,
                          });
                        })
                
                        let _totalPurchaseImei = 0;
                        snapshot3.forEach((doc) => {
                          _totalPurchaseImei += Number(doc.val().quantity);
                        })
                
                        setTotalPurchasedIMEI(_totalPurchaseImei);
                        setTotalIMEI(_totalImei.length);
                        setTotalEmptyPartNo(_totalEmptyImei.length)
  
                        setLoading2(false);
                
                      } else {
                        console.log("No data available");
                        setTotalPurchasedIMEI(0);
                        setTotalIMEI(_totalImei.length);
                        setTotalEmptyPartNo(_totalEmptyImei.length)
  
                        setLoading2(false);
                      }
                    }).catch((error) => {
                      console.error(error);
                    }); 
  
                  } else {
                    console.log("No data available");
                    setTotalPurchasedIMEI(0);
                    setTotalIMEI(0);
                    setTotalEmptyPartNo(0)
                  }
                }).catch((error) => {
                  console.error(error);
                }); 
  
              }).catch((error) => {
                console.error(error);
              }); 
            
  
          // const _arr = getUnique(arr,'imeino');
          setRow(arr);
          
          
           for (let i = 0; i < 11; i += 1) {
  
            const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
            const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
            
            dateArrayRegMyanmar[i] = { date: _priorDate, total :0 }
          } 
  
          arr.forEach((val) => {
            
            if(val.country.toLowerCase() === "myanmar"){
  
                
              _totalMyanmar += 1;
  
              const _RegDate = `${new Date(val.created*1000).getMonth() + 1}/${new Date(val.created*1000).getDate()}/${new Date(val.created*1000).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegMyanmar[i].total += 1;
                }
                
              }
  
            }
           
          }) 
  
  
          setDateRegMyanmar(dateArrayRegMyanmar)
          setTotalMyanmar(_totalMyanmar)
          
          
  
          } else {
            setRow(arr);
            setLoading2(false);
          }
        }).catch((error) => {
          console.error(error);
        });
      }
      

    

    /* const doFetch = async () => {
    // const dataRef = collection(db, "device-registration-1");
    // const q = query(dataRef, orderBy("created", 'asc'), limitToLast(10000));
    // const querySnapshot = await getDocs(collection(db, "device-registration-1"));
    
    const querySnapshot = await getDocs(collection(db, "device-registration-1"));
    const arr = [];
    const dateArrayRegSrilanka = {}
    const dateArrayRegBrunei = {}
    const dateArrayRegNepal = {}
    const dateArrayRegMongolia = {}
    let _totalSrilanka = 0;
    let _totalBrunei = 0;
    let _totalNepal = 0;
    let _totalMongolia = 0;

    // const querySnapshot = await getDocs(q);

    if(querySnapshot.empty)
    {
      if (isMounted) {
        
        setRow(arr);
        setLoading(false);
      }
    }
    else
    {

      querySnapshot.forEach((doc) => {
        if(doc.data().country === _user.country)
        {
          arr.push({
            ...doc.data(),
            id: doc.id,
          });
        }
        else if(_user.role === "super_admin"){
          arr.push({
            ...doc.data(),
            id: doc.id,
          });
        }
      });


      if (isMounted) {
        const _arr = getUnique(arr,'imeino');
        setRow(_arr);
        
        
        for (let i = 0; i < 11; i += 1) {

          const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
              
          const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`

          dateArrayRegSrilanka[i] = { date: _priorDate, total :0 }
          dateArrayRegBrunei[i] = { date: _priorDate, total :0 }
          dateArrayRegNepal[i] = { date: _priorDate, total :0 }
          dateArrayRegMongolia[i] = { date: _priorDate, total :0 }
        } 

        _arr.forEach((val) => {
          
          if(moment(val.purchase_date).isAfter('2023-10-02')){

            if(val.country.toLowerCase() === "srilanka"){

              
              _totalSrilanka += 1;
  
              const _RegDate = `${new Date(val.created.toMillis()).getMonth() + 1}/${new Date(val.created.toMillis()).getDate()}/${new Date(val.created.toMillis()).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegSrilanka[i].total += 1;
                }
                
              }
  
            }
            if(val.country.toLowerCase() === "brunei"){

              // Set the value of 'NYC'
              _totalBrunei += 1;
             
              const _RegDate = `${new Date(val.created.toMillis()).getMonth() + 1}/${new Date(val.created.toMillis()).getDate()}/${new Date(val.created.toMillis()).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
  
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegBrunei[i].total += 1;
                }
                
              }
  
            }
            if(val.country.toLowerCase() === "nepal"){

              
              _totalNepal += 1;
  
              const _RegDate = `${new Date(val.created.toMillis()).getMonth() + 1}/${new Date(val.created.toMillis()).getDate()}/${new Date(val.created.toMillis()).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegNepal[i].total += 1;
                }
                
              }
            }
            if(val.country.toLowerCase() === "mongolia"){

              _totalMongolia += 1;
  
              const _RegDate = `${new Date(val.created.toMillis()).getMonth() + 1}/${new Date(val.created.toMillis()).getDate()}/${new Date(val.created.toMillis()).getFullYear()}`;
              
              for (let i = 0; i < 11; i += 1) {
                const priorDate = new Date(new Date().setDate(new Date().getDate() - i));
                
                const _priorDate = `${priorDate.getMonth() + 1}/${priorDate.getDate()}/${priorDate.getFullYear()}`
  
                if(_priorDate === _RegDate)
                {
                  dateArrayRegMongolia[i].total += 1;
                }
                
              }
            }

          }
         
        })


        setDateRegSrilanka(dateArrayRegSrilanka)
        setDateRegBrunei(dateArrayRegBrunei)
        setDateRegNepal(dateArrayRegNepal)
        setDateRegMongolia(dateArrayRegMongolia)
        setTotalSrilanka(_totalSrilanka)
        setTotalBrunei(_totalBrunei)
        setTotalNepal(_totalNepal)
        setTotalMongolia(_totalMongolia)
        
        setLoading(false);
      } 
      
    }; 
    }
    

    doFetch() // start the async work
      .catch((err) => {
        if (!isMounted) return; // unmounted, ignore.
        // TODO: Handle errors in your component
        console.error("failed to fetch data", err);
      });

    return () => {
      isMounted = false;
    }; */
  }, [value])

  const getUnique = (arr, index) => {

    const unique = arr
         .map(e => e[index])
  
         // store the keys of the unique objects
         .map((e, i, final) => final.indexOf(e) === i && i)
    
         // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);      
  
     return unique;
  }

  return (
    <>
      <Helmet>
      <title> Dashboard | Genxt Services </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="Apple Label">
              <Tab label="iPhone 15" {...a11yProps(0)} />
              <Tab label="iPhone 16" {...a11yProps(1)} />
              <Tab label="Macbook" {...a11yProps(2)} />
              <Tab label="iPad" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {loading ? 
            <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>
              <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
              flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress color="inherit" />
                </Box>
              </Grid>
            </Grid>
          :
            <Grid container spacing={3}>

              {totalSrilanka > 1 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Sri Lanka Registration" total={totalSrilanka} color="warning" icon={'twemoji:flag-sri-lanka'} />
              </Grid> : null}

              {totalBrunei > 1 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Brunei Registration" total={totalBrunei} icon={'twemoji:flag-brunei'} />
              </Grid> : null }

              {totalNepal > 1 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Nepal Registration" total={totalNepal} color="error" icon={'twemoji:flag-nepal'} />
              </Grid> : null }

              {totalMongolia > 1 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Mongolia Registration" total={totalMongolia} color="error" icon={'twemoji:flag-mongolia'} />
              </Grid> : null }

              

              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits
                  title="Registration Stats"
                  chartLabels={[
                    dateRegBrunei[0].date,
                    dateRegBrunei[1].date,
                    dateRegBrunei[2].date,
                    dateRegBrunei[3].date,
                    dateRegBrunei[4].date,
                    dateRegBrunei[5].date,
                    dateRegBrunei[6].date,
                    dateRegBrunei[7].date,
                    dateRegBrunei[8].date,
                    dateRegBrunei[9].date,
                    dateRegBrunei[10].date,
                  ]}
                  chartData={[
                    {
                      name: 'Sri Lanka',
                      type: 'area',
                      fill: 'gradient',
                      data: [dateRegSrilanka[0].total, 
                      dateRegSrilanka[1].total, 
                      dateRegSrilanka[2].total, 
                      dateRegSrilanka[3].total, 
                      dateRegSrilanka[4].total, 
                      dateRegSrilanka[5].total, 
                      dateRegSrilanka[6].total, 
                      dateRegSrilanka[7].total, 
                      dateRegSrilanka[8].total, 
                      dateRegSrilanka[9].total, 
                      dateRegSrilanka[10].total],
                    },
                    {
                      name: 'Brunei',
                      type: 'area',
                      fill: 'gradient',
                      data: [dateRegBrunei[0].total, 
                      dateRegBrunei[1].total, 
                      dateRegBrunei[2].total, 
                      dateRegBrunei[3].total, 
                      dateRegBrunei[4].total, 
                      dateRegBrunei[5].total, 
                      dateRegBrunei[6].total, 
                      dateRegBrunei[7].total, 
                      dateRegBrunei[8].total, 
                      dateRegBrunei[9].total, 
                      dateRegBrunei[10].total],
                    },
                    {
                      name: 'Nepal',
                      type: 'area',
                      fill: 'gradient',
                      data: [dateRegNepal[0].total, 
                      dateRegNepal[1].total, 
                      dateRegNepal[2].total, 
                      dateRegNepal[3].total, 
                      dateRegNepal[4].total, 
                      dateRegNepal[5].total, 
                      dateRegNepal[6].total, 
                      dateRegNepal[7].total, 
                      dateRegNepal[8].total, 
                      dateRegNepal[9].total, 
                      dateRegNepal[10].total],
                    },
                    {
                      name: 'Mongolia',
                      type: 'area',
                      fill: 'gradient',
                      data: [dateRegMongolia[0].total, 
                      dateRegMongolia[1].total, 
                      dateRegMongolia[2].total, 
                      dateRegMongolia[3].total, 
                      dateRegMongolia[4].total, 
                      dateRegMongolia[5].total, 
                      dateRegMongolia[6].total, 
                      dateRegMongolia[7].total, 
                      dateRegMongolia[8].total, 
                      dateRegMongolia[9].total, 
                      dateRegMongolia[10].total],
                    }
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                  title="Current Registration"
                  chartData={[
                    { label: 'Sri Lanka', value: totalSrilanka },
                    { label: 'Brunei', value: totalBrunei },
                    { label: 'Nepal', value: totalNepal },
                    { label: 'Mongolia', value: totalMongolia },
                  ]}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.info.main,
                    theme.palette.warning.main,
                    theme.palette.error.main,
                  ]}
                />
              </Grid>

              {totalIMEI > 1 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total IMEI" total={totalIMEI} color="error" icon={'twemoji:bookmark-tabs'} />
              </Grid> : null }

              {totalIMEI > 1 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Purchased IMEI" total={totalPurchasedIMEI} color="error" icon={'twemoji:mobile-phone'} />
              </Grid> : null }

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Registered IMEI" total={totalSrilanka+totalBrunei+totalNepal+totalMongolia} color="error" icon={'twemoji:mobile-phone'} />
              </Grid>

              {totalEmptyPartNo > 0 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Empty Part No. IMEI" total={totalEmptyPartNo} color="error" icon={'twemoji:mobile-phone'} />
              </Grid> : null }

              

              {/* <Grid item xs={12} md={6} lg={8}>
                <AppConversionRates
                  title="Top 10 Products Stats"
                  chartData={[
                    { label: 'IPHONE 14 PRO MAX DPUR 256GB A2894-ITP', value: 400 },
                    { label: 'IPHONE 14 PRO MAX DPUR 128GB A2894-ITP', value: 430 },
                    { label: 'IPHONE 14 PRO DEEP PUR 128GB A2890-ITP', value: 448 },
                    { label: 'IPHONE 14 PRO DEEP PUR 256GB A2890-ITP', value: 470 },
                    { label: 'IPHONE 14 PRO MAX SBLACK 256GB A2894-ITP', value: 540 },
                    { label: 'IPHONE 14 PURPLE 128GB A2882-ITP', value: 580 },
                    { label: 'IPHONE 14 PRO MAX SBLACK 128GB A2894-ITP', value: 690 },
                    { label: 'IPHONE 14 BLUE 128GB A2882-ITP', value: 1100 },
                    { label: 'IPHONE 14 PRO MAX GOLD 256GB A2894-ITP', value: 1200 },
                    { label: 'IPHONE 14 PRO SPACE BLK 128GB A2890-ITP', value: 1380 },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentSubject
                  title="Current Subject"
                  chartLabels={['64gb', '128gb', '256gb', '512gb', '1T', '2T']}
                  chartData={[
                    { name: 'Iphone Series 13', data: [80, 50, 30, 40, 100, 20] },
                    { name: 'Iphone Series 14', data: [20, 30, 40, 80, 20, 80] },
                  ]}
                  chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                />
                </Grid> */}

              
            </Grid>}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            iPhone 16
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
          {loading2 ? 
            <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>
              <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
              flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress color="inherit" />
                </Box>
              </Grid>
            </Grid>
          :
            <Grid container spacing={3}>

              {totalMyanmar > 1 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Myanmar Registration" total={totalMyanmar} color="warning" icon={'twemoji:flag-myanmar'} />
              </Grid> : null}

              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits
                  title="Registration Stats"
                  chartLabels={[
                    dateRegMyanmar[0].date,
                    dateRegMyanmar[1].date,
                    dateRegMyanmar[2].date,
                    dateRegMyanmar[3].date,
                    dateRegMyanmar[4].date,
                    dateRegMyanmar[5].date,
                    dateRegMyanmar[6].date,
                    dateRegMyanmar[7].date,
                    dateRegMyanmar[8].date,
                    dateRegMyanmar[9].date,
                    dateRegMyanmar[10].date,
                  ]}
                  chartData={[
                    {
                      name: 'Myanmar',
                      type: 'area',
                      fill: 'gradient',
                      data: [dateRegMyanmar[0].total, 
                      dateRegMyanmar[1].total, 
                      dateRegMyanmar[2].total, 
                      dateRegMyanmar[3].total, 
                      dateRegMyanmar[4].total, 
                      dateRegMyanmar[5].total, 
                      dateRegMyanmar[6].total, 
                      dateRegMyanmar[7].total, 
                      dateRegMyanmar[8].total, 
                      dateRegMyanmar[9].total, 
                      dateRegMyanmar[10].total],
                    }
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                  title="Current Registration"
                  chartData={[
                    { label: 'Myanmar', value: totalMyanmar },
                  ]}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.info.main,
                    theme.palette.warning.main,
                    theme.palette.error.main,
                  ]}
                />
              </Grid>

              {totalIMEI > 1 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Serial Number" total={totalIMEI} color="error" icon={'twemoji:bookmark-tabs'} />
              </Grid> : null }

              {totalIMEI > 1 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Purchased Serial Number" total={totalPurchasedIMEI} color="error" icon={'twemoji:mobile-phone'} />
              </Grid> : null }

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Registered Serial Number" total={totalMyanmar} color="error" icon={'twemoji:mobile-phone'} />
              </Grid>

              {totalEmptyPartNo > 0 ? <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Empty Part No. Serial Number" total={totalEmptyPartNo} color="error" icon={'twemoji:mobile-phone'} />
              </Grid> : null }

              
            </Grid>}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            iPad
          </CustomTabPanel>
        </Box>

        
      </Container>
    </>
  );
}
