import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';
import Box from '@mui/material/Box';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, serverTimestamp, Timestamp, getDocs, collection, query, where } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";
import { countBy } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import countryList from 'react-select-country-list'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiFileInput } from 'mui-file-input'
import { getStorage, ref, uploadBytes } from "firebase/storage";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import moment from 'moment';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const rtdb = getDatabase(app);

// ----------------------------------------------------------------------

const partnolist = [
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVU3X/A",
    "capacity": "128GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVV3X/A",
    "capacity": "128GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVW3X/A",
    "capacity": "128GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVX3X/A",
    "capacity": "128GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVY3X/A",
    "capacity": "128GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXWN3X/A",
    "capacity": "256GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXWV3X/A",
    "capacity": "256GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXY13X/A",
    "capacity": "256GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXY23X/A",
    "capacity": "256GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXY53X/A",
    "capacity": "256GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY1P3X/A",
    "capacity": "512GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY1X3X/A",
    "capacity": "512GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY253X/A",
    "capacity": "512GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY2D3X/A",
    "capacity": "512GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY2J3X/A",
    "capacity": "512GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYE73X/A",
    "capacity": "128GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYE93X/A",
    "capacity": "128GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEA3X/A",
    "capacity": "128GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEC3X/A",
    "capacity": "128GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYED3X/A",
    "capacity": "128GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEE3X/A",
    "capacity": "256GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEF3X/A",
    "capacity": "256GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEG3X/A",
    "capacity": "256GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEH3X/A",
    "capacity": "256GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEJ3X/A",
    "capacity": "256GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEK3X/A",
    "capacity": "512GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEP3X/A",
    "capacity": "512GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEQ3X/A",
    "capacity": "512GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYER3X/A",
    "capacity": "512GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEU3X/A",
    "capacity": "512GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYND3X/A",
    "capacity": "128GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNE3X/A",
    "capacity": "128GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNF3X/A",
    "capacity": "128GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNG3X/A",
    "capacity": "128GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNH3X/A",
    "capacity": "256GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNJ3X/A",
    "capacity": "256GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNK3X/A",
    "capacity": "256GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNL3X/A",
    "capacity": "256GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNM3X/A",
    "capacity": "512GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNN3X/A",
    "capacity": "512GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNP3X/A",
    "capacity": "512GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNQ3X/A",
    "capacity": "512GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNR3X/A",
    "capacity": "1TB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNT3X/A",
    "capacity": "1TB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNW3X/A",
    "capacity": "1TB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNX3X/A",
    "capacity": "1TB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYWV3X/A",
    "capacity": "256GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYWW3X/A",
    "capacity": "256GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYWX3X/A",
    "capacity": "256GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYWY3X/A",
    "capacity": "256GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX03X/A",
    "capacity": "512GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX13X/A",
    "capacity": "512GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX23X/A",
    "capacity": "512GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX33X/A",
    "capacity": "512GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX43X/A",
    "capacity": "1TB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX53X/A",
    "capacity": "1TB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX63X/A",
    "capacity": "1TB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX73X/A",
    "capacity": "1TB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "capacity": "128GB",
    "brand": "Apple",
    "color": "MIDNIGHT",
    "name": "IPHONE 13 MINI",
    "partno": "MLK03ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 13 MINI",
      "partno": "MLK13ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "PINK",
      "name": "IPHONE 13 MINI",
      "partno": "MLK23ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 13 MINI",
      "partno": "MLK33ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 13 MINI",
      "partno": "MLK43ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 13 MINI",
      "partno": "MLK53ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 13 MINI",
      "partno": "MLK63ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "PINK",
      "name": "IPHONE 13 MINI",
      "partno": "MLK73ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 13 MINI",
      "partno": "MLK83ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 13 MINI",
      "partno": "MLK93ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 13 MINI",
      "partno": "MLKA3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 13 MINI",
      "partno": "MLKC3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "PINK",
      "name": "IPHONE 13 MINI",
      "partno": "MLKD3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 13 MINI",
      "partno": "MLKE3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 13 MINI",
      "partno": "MLKF3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "GRAPHITE",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLL63ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLL73ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLL83ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "SIERRA BLUE",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLL93ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "GRAPHITE",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLA3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLC3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLD3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "SIERRA BLUE",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLE3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "GRAPHITE",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLF3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLG3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLH3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "SIERRA BLUE",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLJ3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "GRAPHITE",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLK3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLL3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLM3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "SIERRA BLUE",
      "name": "IPHONE 13 PRO MAX",
      "partno": "MLLN3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 13",
      "partno": "MLPF3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 13",
      "partno": "MLPG3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "PINK",
      "name": "IPHONE 13",
      "partno": "MLPH3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 13",
      "partno": "MLPJ3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 13",
      "partno": "MLPK3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 13",
      "partno": "MLQ63ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 13",
      "partno": "MLQ73ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "PINK",
      "name": "IPHONE 13",
      "partno": "MLQ83ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 13",
      "partno": "MLQ93ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 13",
      "partno": "MLQA3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 13",
      "partno": "MLQC3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 13",
      "partno": "MLQD3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "PINK",
      "name": "IPHONE 13",
      "partno": "MLQE3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 13",
      "partno": "MLQF3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 13",
      "partno": "MLQG3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "GRAPHITE",
      "name": "IPHONE 13 PRO",
      "partno": "MLV93ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 13 PRO",
      "partno": "MLVA3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 13 PRO",
      "partno": "MLVC3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "SIERRA BLUE",
      "name": "IPHONE 13 PRO",
      "partno": "MLVD3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "GRAPHITE",
      "name": "IPHONE 13 PRO",
      "partno": "MLVE3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 13 PRO",
      "partno": "MLVF3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "GRAPHITE",
      "name": "IPHONE 13 PRO",
      "partno": "MLVH3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 13 PRO",
      "partno": "MLVK3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 13 PRO",
      "partno": "MLVN3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "SIERRA BLUE",
      "name": "IPHONE 13 PRO",
      "partno": "MLVP3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 13 PRO",
      "partno": "MLVQ3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "SIERRA BLUE",
      "name": "IPHONE 13 PRO",
      "partno": "MLVU3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "GRAPHITE",
      "name": "IPHONE 13 PRO",
      "partno": "MLVV3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 13 PRO",
      "partno": "MLVW3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 13 PRO",
      "partno": "MLVY3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "SIERRA BLUE",
      "name": "IPHONE 13 PRO",
      "partno": "MLW03ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "ALPINE GREEN",
      "name": "IPHONE 13 PRO MAX A2643-ITP",
      "partno": "MNCY3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "ALPINE GREEN",
      "name": "IPHONE 13 PRO MAX A2643-ITP",
      "partno": "MND03ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "ALPINE GREEN",
      "name": "IPHONE 13 PRO MAX A2643-ITP",
      "partno": "MND13ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "ALPINE GREEN",
      "name": "IPHONE 13 PRO MAX A2643-ITP",
      "partno": "MND23ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "ALPINE GREEN",
      "name": "IPHONE 13 PRO A2638-ITP",
      "partno": "MNE23ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "ALPINE GREEN",
      "name": "IPHONE 13 PRO A2638-ITP",
      "partno": "MNE33ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "ALPINE GREEN",
      "name": "IPHONE 13 PRO A2638-ITP",
      "partno": "MNE43ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "ALPINE GREEN",
      "name": "IPHONE 13 PRO A2638-ITP",
      "partno": "MNE53ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "GREEN",
      "name": "IPHONE 13 MINI A2628-ITP",
      "partno": "MNFF3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "GREEN",
      "name": "IPHONE 13 MINI A2628-ITP",
      "partno": "MNFG3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "GREEN",
      "name": "IPHONE 13 MINI A2628-ITP",
      "partno": "MNFH3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "GREEN",
      "name": "IPHONE 13",
      "partno": "MNGK3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "GREEN",
      "name": "IPHONE 13",
      "partno": "MNGL3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "GREEN",
      "name": "IPHONE 13",
      "partno": "MNGM3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPUF3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPUR3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "PURPLE",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPV03ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPVA3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPVN3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPVX3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPW43ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "PURPLE",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPWA3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPWH3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPWP3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPWW3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPX33ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "PURPLE",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPX93ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPXG3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MPXN3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "SPACE BLACK",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MPXV3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ023ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ083ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "DEEP PURPLE",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ0G3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "SPACE BLACK",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ0T3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ103ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ183ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "DEEP PURPLE",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ1F3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "SPACE BLACK",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ1M3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ1W3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ233ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "DEEP PURPLE",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ293ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "SPACE BLACK",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ2G3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ2N3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ2V3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "DEEP PURPLE",
      "name": "IPHONE 14 PRO A2890-ITP",
      "partno": "MQ323ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ4X3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ4Y3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "PURPLE",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ503ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ513ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ523ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ533ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ553ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "PURPLE",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ563ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ573ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ583ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "MIDNIGHT",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ593ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "STARLIGHT",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ5D3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "PURPLE",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ5E3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "RED",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ5F3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "BLUE",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MQ5G3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "BLACK",
      "name": "iPhone 14 Pro Max",
      "partno": "MQ9P3ZD/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "SPACE BLACK",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQ9P3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQ9Q3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQ9R3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "PURPLE",
      "name": "iPhone 14 Pro Max",
      "partno": "MQ9T3ZD/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "DEEP PURPLE",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQ9T3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "SPACE BLACK",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQ9U3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQ9V3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQ9W3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "DEEP PURPLE",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQ9X3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "SPACE BLACK",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQAF3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQAH3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQAJ3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "DEEP PURPLE",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQAM3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "SPACE BLACK",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQC23ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "SILVER",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQC33ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "GOLD",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQC43ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "DEEP PURPLE",
      "name": "IPHONE 14 PRO MAX A2894-ITP",
      "partno": "MQC53ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "YELLOW",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MR3X3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "YELLOW",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MR3Y3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "YELLOW",
      "name": "IPHONE 14 A2882-ITP",
      "partno": "MR513ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "YELLOW",
      "name": "IPHONE 14",
      "partno": "MR563LL/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "YELLOW",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MR6932P/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "YELLOW",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MR6D3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "YELLOW",
      "name": "IPHONE 14 PLUS A2886-ITP",
      "partno": "MR6G3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15",
      "partno": "MTP03ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 15",
      "partno": "MTP13ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Yellow",
      "name": "iPhone 15",
      "partno": "MTP23ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15",
      "partno": "MTP43ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Green",
      "name": "iPhone 15",
      "partno": "MTP53ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15",
      "partno": "MTP63ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 15",
      "partno": "MTP73ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Yellow",
      "name": "iPhone 15",
      "partno": "MTP83ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15",
      "partno": "MTP93ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Green",
      "name": "iPhone 15",
      "partno": "MTPA3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15",
      "partno": "MTPC3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 15",
      "partno": "MTPD3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Yellow",
      "name": "iPhone 15",
      "partno": "MTPF3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15",
      "partno": "MTPG3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Green",
      "name": "iPhone 15",
      "partno": "MTPH3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Pro",
      "partno": "MTUV3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 15 Pro",
      "partno": "MTUW3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Natural",
      "name": "iPhone 15 Pro",
      "partno": "MTUX3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Blue Titanium",
      "name": "iPhone 15 Pro",
      "partno": "MTV03ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Pro",
      "partno": "MTV13ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 15 Pro",
      "partno": "MTV43ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Natural Titanium",
      "name": "iPhone 15 Pro",
      "partno": "MTV53ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15 Pro",
      "partno": "MTV63ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Pro",
      "partno": "MTV73ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 15 Pro",
      "partno": "MTV83ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Natural",
      "name": "iPhone 15 Pro",
      "partno": "MTV93ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15 Pro",
      "partno": "MTVA3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Pro",
      "partno": "MTVC3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 15 Pro",
      "partno": "MTVD3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "Natural",
      "name": "iPhone 15 Pro",
      "partno": "MTVF3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15 Pro",
      "partno": "MTVG3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Plus",
      "partno": "MU0Y3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 15 Plus",
      "partno": "MU103ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Yellow",
      "name": "iPhone 15 Plus",
      "partno": "MU123ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15 Plus",
      "partno": "MU163ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Green",
      "name": "iPhone 15 Plus",
      "partno": "MU173ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Plus",
      "partno": "MU183ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 15 Plus",
      "partno": "MU193ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Yellow",
      "name": "iPhone 15 Plus",
      "partno": "MU1D3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15 Plus",
      "partno": "MU1F3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Green",
      "name": "iPhone 15 Plus",
      "partno": "MU1G3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Plus",
      "partno": "MU1H3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 15 Plus",
      "partno": "MU1J3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Yellow",
      "name": "iPhone 15 Plus",
      "partno": "MU1M3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15 Plus",
      "partno": "MU1P3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Green",
      "name": "iPhone 15 Plus",
      "partno": "MU1Q3ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Pro Max",
      "partno": "MU773ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 15 Pro Max",
      "partno": "MU783ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Natural Titanium",
      "name": "iPhone 15 Pro Max",
      "partno": "MU793ZP/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Blue Titanium",
      "name": "iPhone 15 Pro Max",
      "partno": "MU7A3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Pro Max",
      "partno": "MU7C3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 15 Pro Max",
      "partno": "MU7D3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Natural",
      "name": "iPhone 15 Pro Max",
      "partno": "MU7E3ZP/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15 Pro Max",
      "partno": "MU7F3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 15 Pro Max",
      "partno": "MU7G3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 15 Pro Max",
      "partno": "MU7H3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "Natural",
      "name": "iPhone 15 Pro Max",
      "partno": "MU7J3ZP/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "Blue",
      "name": "iPhone 15 Pro Max",
      "partno": "MU7K3ZP/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 16 Plus",
      "partno": "MXVU3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 16 Plus",
      "partno": "MXVV3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 16 Plus",
      "partno": "MXVW3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Ultramarine",
      "name": "iPhone 16 Plus",
      "partno": "MXVX3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Teal",
      "name": "iPhone 16 Plus",
      "partno": "MXVY3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 16 Plus",
      "partno": "MXWN3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 16 Plus",
      "partno": "MXWV3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 16 Plus",
      "partno": "MXY13X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Ultramarine",
      "name": "iPhone 16 Plus",
      "partno": "MXY23X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Teal",
      "name": "iPhone 16 Plus",
      "partno": "MXY53X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 16",
      "partno": "MYE73X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 16",
      "partno": "MYE93X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 16",
      "partno": "MYEA3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Ultramarine",
      "name": "iPhone 16",
      "partno": "MYEC3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Teal",
      "name": "iPhone 16",
      "partno": "MYED3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Black",
      "name": "iPhone 16",
      "partno": "MYEE3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "White",
      "name": "iPhone 16",
      "partno": "MYEF3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Pink",
      "name": "iPhone 16",
      "partno": "MYEG3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Ultramarine",
      "name": "iPhone 16",
      "partno": "MYEH3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Teal",
      "name": "iPhone 16",
      "partno": "MYEJ3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Black Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYND3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "White Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNE3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Desert Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNF3X/A"
  },
  {
      "capacity": "128GB",
      "brand": "Apple",
      "color": "Natural Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNG3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Black Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNH3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "White Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNJ3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Desert Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNK3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Natural Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNL3X/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Black Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNM3X/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "White Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNN3X/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Desert Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNP3X/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Natural Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNQ3X/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "White Titanium",
      "name": "iPhone 16 Pro",
      "partno": "MYNT3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Black Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYWV3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "White Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYWW3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Desert Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYWX3X/A"
  },
  {
      "capacity": "256GB",
      "brand": "Apple",
      "color": "Natural Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYWY3X/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Black Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYX03X/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "White Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYX13X/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Desert Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYX23X/A"
  },
  {
      "capacity": "512GB",
      "brand": "Apple",
      "color": "Natural Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYX33X/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "Black Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYX43X/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "White Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYX53X/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "Desert Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYX63X/A"
  },
  {
      "capacity": "1TB",
      "brand": "Apple",
      "color": "Natural Titanium",
      "name": "iPhone 16 Pro Max",
      "partno": "MYX73X/A"
  }
]

export default function SearchRegistration() {

  const [fullname, setFullname] = React.useState('');
  const [clientId, setClientId] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [mobileNum, setMobileNum] = React.useState('');
  const [homeAddress, setHomeAddress] = React.useState('');
  const [city, setCity] = React.useState('');
  const [deviceImei, setDeviceImei] = React.useState('');
  const [serialNumber, setSerialNumber] = React.useState('');
  const [agreement, setAgreement] = React.useState(false);
  const [date, setDate] = React.useState(dayjs());
  const [file, setFile] = React.useState(null);

  const [fullnameError, setFullnameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [cityError, setCityError] = React.useState(false);
  const [deviceImeiError, setDeviceImeiError] = React.useState(false);
  const [serialNumberError, setSerialNumberError] = React.useState(false);
  const [clientIdError, setClientIdError] = React.useState(false);
  const [mobileNumError, setMobileNumError] = React.useState(false);
  const [fileInputError, setFileInputError] = React.useState(false);
  const [imeiRegistered, setImeiRegistered] = React.useState(false);
  const [serialNoRegistered, setSerialNoRegistered] = React.useState(false);
  const [imeiList, setImeiList] = React.useState([]);
  const [deviceRegList, setDeviceRegList] = React.useState([]);

  const [currentCountryCode, setCurrentCountryCode] = React.useState("LK");
  const [currentCountry, setCurrentCountry] = React.useState("srilanka");
  const [currentCountryName, setCurrentCountryName] = React.useState("Sri Lanka");
  const [termName, setTermName] = React.useState("Generation Next Communications Lanka Pvt Ltd");

  const [alignment, setAlignment] = React.useState('nic');

  const [loading, setLoading] = React.useState(false);
  const [thankyou, setThankyou] = React.useState(false);

  const options = React.useMemo(() => countryList().getData(), [])

  const [open, setOpen] = React.useState(false);

  const [resellerNameError, setResellerNameError] = React.useState(false);
  const [resellerName, setResellerName] = React.useState('');

  const [phoneModel, setPhoneModel] = React.useState(null);
  const [phoneColor, setPhoneColor] = React.useState(null);
  const [phoneCapacity, setPhoneCapacity] = React.useState(null);
  const [partno, setPartNo] = React.useState(null);


  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChangeUploadfile = (newFile) => {
    setFile(newFile);
  };

  const handleChangeAgreement = (event) => {
    setAgreement(event.target.checked)
  };

  const handleChangeFullname = (event) => {
    setFullname(event.target.value)
  };

  const handleChangeClientId = (event) => {
    setClientId(event.target.value)
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
  };

  const handleChangeMobileNum = (event) => {
    setMobileNum(event.target.value)
  };

  const handleChangeHomeAddress = (event) => {
    setHomeAddress(event.target.value)
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value)
  };

  const handleChangeResellerName = (event) => {
    setResellerName(event.target.value)
  };

const handleChangeDeviceImei = (event) => {
    setDeviceImei(event.target.value)

    if(event.target.value &&  event.target.value.length > 7)
      {

        get(child(dbref(rtdb), `imei/${event.target.value.trim()}`)).then((snapshot) => {
          if (snapshot.exists()) {
    
            if(Number(snapshot.val().imeino.toString().trim()) === Number(event.target.value.trim()))
            {
              const currentIndex = partnolist.map(val=> val.partno).indexOf(snapshot.val().partno.toUpperCase().trim());
              
              if(currentIndex !== -1){
                setPartNo(partnolist[currentIndex].partno.toUpperCase().trim());
                setPhoneModel(partnolist[currentIndex].name.toLocaleLowerCase().trim())
                setPhoneColor(partnolist[currentIndex].color.toLocaleLowerCase().trim())
                setPhoneCapacity(partnolist[currentIndex].capacity.toLocaleLowerCase().trim())
              }
              else
              {
                setPartNo(null)
                setPhoneModel(null)
                setPhoneColor(null)
                setPhoneCapacity(null)
              }
            }
            else
            {
              setPartNo(null)
              setPhoneModel(null)
              setPhoneColor(null)
              setPhoneCapacity(null)
            }
          }
          else
          {
            setPartNo(null)
            setPhoneModel(null)
            setPhoneColor(null)
            setPhoneCapacity(null)
          }
        });
      }
      else
      {
        setPartNo(null)
        setPhoneModel(null)
        setPhoneColor(null)
        setPhoneCapacity(null)
      }
  };

  const handleChangeSerialNumber = (event) => {
    setSerialNumber(event.target.value)
  };

  const leftPad = (number, targetLength) => {
    let output = `${number}`;
    while (output.length < targetLength) {
        output = `0${output}`;
    }
    return output;
  }

  const saveData = () => {
    setLoading(true);

    const storage = getStorage(app);
    const storageRef = ref(storage, `invoice/${Timestamp.fromDate(new Date()).toMillis()}_${file.name.replace(/\s/g,'')}`);

    let sequenceID = 0;

      get(child(dbref(rtdb), `device-registration-sequence`)).then((snapshot) => {
        if (snapshot.exists()) {
          sequenceID = snapshot.val().count + 1;
        } else {
          sequenceID = 1;
        }
        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, file).then((snapshot) => {

          const ID = `GN-SRWEW-${leftPad(sequenceID, 7)}`;

          try
          {
            
            set(dbref(rtdb, `device-registration/${ID}`), {
              full_name: fullname || null,
              id: ID,
              nic: alignment === "nic" ? clientId : null,
              passport: alignment === "passport" ? clientId : null,
              email: email || null,
              reseller_name: resellerName || null,
              file_fullPath: snapshot.metadata.fullPath || null,
              file_contentType: snapshot.metadata.contentType || null,
              home_address: homeAddress || null,
              city: city || null,
              country: currentCountry,
              imeino: deviceImei || null,
              serialno: serialNumber ||  null,
              mobile: mobileNum || null,
              purchase_date: date.$d.toISOString().substring(0, 10) || null,
              created: Timestamp.fromDate(new Date()).seconds,
              status: "pending_verification",
              phone_partno: partno,
              phone_model: phoneModel,
              phone_color: phoneColor,
              phone_capacity: phoneCapacity,
              ew:true,
              sc:true
            }).then(()=>{

              set(child(dbref(rtdb), `device-registration-sequence`), { count: sequenceID })
              
              const emailID = `${currentCountryCode}${Timestamp.fromDate(new Date()).toMillis()}`;
              try
              {
                setDoc(doc(db, "mail", emailID), {
                  to: email,
                  message: {
                    subject: 'Extended Warranty Successful Registration',
                    html: `<!DOCTYPE html>
                    <html>
                    <head>
                    
                      <meta charset="utf-8">
                      <meta http-equiv="x-ua-compatible" content="ie=edge">
                      <title>Extended Warranty Successful Registration</title>
                      <meta name="viewport" content="width=device-width, initial-scale=1">
                      <style type="text/css">
                      /**
                       * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
                       */
                      @media screen {
                        @font-face {
                          font-family: 'Source Sans Pro';
                          font-style: normal;
                          font-weight: 400;
                          src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
                        }
                        @font-face {
                          font-family: 'Source Sans Pro';
                          font-style: normal;
                          font-weight: 700;
                          src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
                        }
                      }
                      /**
                       * Avoid browser level font resizing.
                       * 1. Windows Mobile
                       * 2. iOS / OSX
                       */
                      body,
                      table,
                      td,
                      a {
                        -ms-text-size-adjust: 100%; /* 1 */
                        -webkit-text-size-adjust: 100%; /* 2 */
                      }
                      /**
                       * Remove extra space added to tables and cells in Outlook.
                       */
                      table,
                      td {
                        mso-table-rspace: 0pt;
                        mso-table-lspace: 0pt;
                      }
                      /**
                       * Better fluid images in Internet Explorer.
                       */
                      img {
                        -ms-interpolation-mode: bicubic;
                      }
                      /**
                       * Remove blue links for iOS devices.
                       */
                      a[x-apple-data-detectors] {
                        font-family: inherit !important;
                        font-size: inherit !important;
                        font-weight: inherit !important;
                        line-height: inherit !important;
                        color: inherit !important;
                        text-decoration: none !important;
                      }
                      /**
                       * Fix centering issues in Android 4.4.
                       */
                      div[style*="margin: 16px 0;"] {
                        margin: 0 !important;
                      }
                      body {
                        width: 100% !important;
                        height: 100% !important;
                        padding: 0 !important;
                        margin: 0 !important;
                      }
                      /**
                       * Collapse table borders to avoid space between cells.
                       */
                      table {
                        border-collapse: collapse !important;
                      }
                      a {
                        color: #1a82e2;
                      }
                      img {
                        height: auto;
                        line-height: 100%;
                        text-decoration: none;
                        border: 0;
                        outline: none;
                      }
                      </style>
                    
                    </head>
                    <body style="background-color: #e9ecef;">
                    
                      <!-- start preheader -->
                      <div class="preheader" style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
                      Thank you for completing your registration of your protection care plan for your iPhone.
                      </div>
                      <!-- end preheader -->
                    
                      <!-- start body -->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    
                        <!-- start logo -->
                        <tr>
                          <td align="center" bgcolor="#e9ecef">
                            <!--[if (gte mso 9)|(IE)]>
                            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                            <tr>
                            <td align="center" valign="top" width="600">
                            <![endif]-->
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                              <tr>
                                <td align="center" valign="top" style="padding: 36px 24px;">
                                  <a href="https://genxt-9f006.web.app/" target="_blank" style="display: inline-block;">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/genxt-9f006.appspot.com/o/logo%2FGenxt-logo.png?alt=media&token=26854a16-dec8-48c7-bfb7-bd509fd23e3b" alt="Logo" border="0" width="150" style="display: block; width: 150px; max-width: 150px; min-width: 48px;">
                                  </a>
                                </td>
                              </tr>
                            </table>
                            <!--[if (gte mso 9)|(IE)]>
                            </td>
                            </tr>
                            </table>
                            <![endif]-->
                          </td>
                        </tr>
                        <!-- end logo -->
                    
                        <!-- start copy block -->
                        <tr>
                          <td align="center" bgcolor="#e9ecef">
                            <!--[if (gte mso 9)|(IE)]>
                            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                            <tr>
                            <td align="center" valign="top" width="600">
                            <![endif]-->
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

                            <!-- start copy -->
                              <tr>
                                <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                  <p style="margin: 0;">Dear ${fullname},</p>
                                </td>
                              </tr>
                              <!-- end copy -->
                    
                              <!-- start copy -->
                              <tr>
                                <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                  <p style="margin: 0;">Thank you for completing your registration of your protection care plan for your iPhone.</p>
                                </td>
                              </tr>
                              <!-- end copy -->
                    
                              <!-- start copy -->
                              <tr>
                                <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                  <p style="margin: 0;">Your coverage for the iPhone is as follows:</p>
                                  <p style="margin: 0; margin-top: 10">1. Extended Warranty Coverage From 
                                  ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").add(1, "years").format("Do MMM YYYY")} to ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").add(2, "years").format("Do MMM YYYY")}</p>
                                  <p style="margin: 0;">2. Screen Crack Coverage From 
                                  ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").format("Do MMM YYYY")} to ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").add(1, "years").format("Do MMM YYYY")}</p>
                                </td>
                              </tr>
                              <!-- end copy -->
                    
                              <!-- start copy -->
                              <tr>
                                <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                                  <p style="margin: 0;">Cheers,<br> Generation Next Communications Pte Ltd </p>
                                </td>
                              </tr>
                              <!-- end copy -->
                    
                            </table>
                            <!--[if (gte mso 9)|(IE)]>
                            </td>
                            </tr>
                            </table>
                            <![endif]-->
                          </td>
                        </tr>
                        <!-- end copy block -->
                    
                        <!-- start footer -->
                        <tr>
                          <td align="center" bgcolor="#e9ecef" style="padding: 24px;">
                            <!--[if (gte mso 9)|(IE)]>
                            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                            <tr>
                            <td align="center" valign="top" width="600">
                            <![endif]-->
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                    
                              <!-- start permission -->
                              <tr>
                                <td align="center" bgcolor="#e9ecef" style="padding: 12px 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">
                                  <p style="margin: 0;">This Care plan is underwritten by Alliance Insurance. All Service Requests are subjected to the respective countries’ terms and conditions list <a href="https://genxt-9f006.web.app/terms-and-conditions" target="_blank">here.</a></p>
                                </td>
                              </tr>
                              <!-- end permission -->
                    
                    
                            </table>
                            <!--[if (gte mso 9)|(IE)]>
                            </td>
                            </tr>
                            </table>
                            <![endif]-->
                          </td>
                        </tr>
                        <!-- end footer -->
                    
                      </table>
                      <!-- end body -->
                    
                    </body>
                    </html>`,
                  }
                }).then(()=>{

                  setLoading(false);
                  setThankyou(true);

                  setFullnameError(false);
                  setEmailError(false);
                  setCityError(false);
                  setResellerNameError(false);
                  setDeviceImeiError(false);
                  setSerialNumberError(false);
                  setFileInputError(false);
                  setMobileNumError(false);
                  setClientIdError(false);
                  setAlignment('nic');

                  setFullname('');
                  setClientId('');
                  setEmail('');
                  setHomeAddress('');
                  setCity('');
                  setResellerName('');
                  setDeviceImei('');
                  setSerialNumber('');
                  setDate(dayjs());
                  setFile(null);
                  setAgreement(false);

                })
              }
              catch(e)
              {
                // console.log(e)
              }
              
            
            })
          }
          catch(e)
          {
            // console.log(e)
          }
          
        });
      })
  }

  const submitHandler = async (e) => {
    
    e.preventDefault();

    setLoading(true);

    let fullnamePass = false;
    let emailPass = false;
    let cityPass = false;
    let resellerNamePass = false;
    let imeiPass = false;
    let imeiRegPass = false;
    let serialNoRegPass = false;
    let serialNumberPass = false;
    let mobileNumPass = false;
    let filePass = false;

    
    if(file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf" )){
      setFileInputError(false)
      filePass = true;
    }
    else
    {
      setFileInputError(true)
    }

    if(fullname.length > 5){
      setFullnameError(false)
      fullnamePass = true;
    }
    else
    {
      setFullnameError(true)
    }

    if(mobileNum.length > 5){
      setMobileNumError(false)
      mobileNumPass = true;
    }
    else
    {
      setMobileNumError(true)
    }


    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(email.length > 0 && emailRegex.test(email)){
      setEmailError(false)
      emailPass = true;
    }
    else
    {
      setEmailError(true)
    }

    if(city.length > 2){
      setCityError(false)
      cityPass = true;
    }
    else
    {
      setCityError(true)
    }

    if(resellerName.length > 2){
      setResellerNameError(false)
      resellerNamePass = true;
    }
    else
    {
      setResellerNameError(true)
    }

    let imeiInside = false;
    let q3pass = false;
    const registeredImei = [];
    let q4pass = false;

    get(child(dbref(rtdb), `imei`)).then((snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((doc) => {
          if(Number(doc.val().imeino) === Number(deviceImei.trim()) && doc.val().country.toUpperCase() === currentCountry.toUpperCase())
          {
            imeiInside = true;
          }

          if(doc.val().serialno.toUpperCase().trim() === serialNumber.toUpperCase().trim() && doc.val().country.toUpperCase() === currentCountry.toUpperCase())
          {
            q3pass = true;
          }
        })

        if(imeiInside){
          setDeviceImeiError(false)
          imeiPass = true;
        }
        else
        {
          setDeviceImeiError(true)
        }

        if(q3pass){
          setSerialNumberError(false)
          serialNumberPass = true;
        }
        else
        {
          setSerialNumberError(true)
        }

        get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
          if (snapshot.exists()) {
            
            snapshot.forEach((doc) => {
              if(Number(doc.val().imeino) === Number(deviceImei.trim()) && doc.val().country.toUpperCase() === currentCountry.toUpperCase())
              {
                registeredImei.push(doc.val());
              }
    
              if(doc.val().serialno.toUpperCase().trim() === serialNumber.toUpperCase().trim() && doc.val().country.toUpperCase() === currentCountry.toUpperCase())
              {
                q4pass = true;
              }
            })
    
            if(registeredImei.length === 0){
              setImeiRegistered(false)
              imeiRegPass = true;
            }
            else
            {
              setImeiRegistered(true)
            }

            if(q4pass === false){
              setSerialNoRegistered(false)
              serialNoRegPass = true;
            }
            else
            {
              setSerialNoRegistered(true)
            }

            if(fullnamePass === true && 
              emailPass === true && 
              cityPass === true && 
              imeiPass === true &&
              mobileNumPass === true &&
              filePass === true &&
              imeiRegPass === true &&
              serialNoRegPass === true &&
              resellerNamePass === true &&
              serialNumberPass === true && agreement === true)
            {
                
        
              setOpen(true);
              setLoading(false);
                
            }
            else{
              setLoading(false);
            }

          }
        })

      }
    })


    /* const imeiRef = collection(db, "imei");
    const q1 = query(imeiRef, where("imeino", "==", Number(deviceImei.trim())), where("country", "==", currentCountry));

    const querySnapshot1 = await getDocs(q1);

    const imeiRefNum = collection(db, "imei");
    const q1Num = query(imeiRefNum, where("imeino", "==", deviceImei.trim()), where("country", "==", currentCountry));

    const querySnapshot1Num = await getDocs(q1Num);

    if(querySnapshot1.size > 0){
      imeiInside = true;
    }
    
    if(querySnapshot1Num.size > 0){
      imeiInside = true;
    } */

    

    
    

    /* const deviceRef = collection(db, "device-registration-1");
    const q2 = query(deviceRef, where("imeino", "==", deviceImei.trim()), where("country", "==", currentCountry));

    const querySnapshot2 = await getDocs(q2);

    if(querySnapshot2.size === 0){
      setImeiRegistered(false)
      imeiRegPass = true;
    }
    else
    {
      setImeiRegistered(true)
    } */

    /* const imeiRef3 = collection(db, "imei");
    const q3 = query(imeiRef3, where("country", "==", currentCountry));

    const querySnapshot3 = await getDocs(q3);

    
    querySnapshot3.forEach(val =>{
      if(val.data().serialno.toString().toUpperCase().trim() === serialNumber.toUpperCase().trim())
      {
        q3pass = true;
      }
    }) */

    

    /* const imeiRef4 = collection(db, "device-registration-1");
    const q4 = query(imeiRef4, where("country", "==", currentCountry));

    const querySnapshot4 = await getDocs(q4);

    
    querySnapshot4.forEach(val =>{

      if(val.data().serialno.toString().toUpperCase().trim() === serialNumber.toUpperCase().trim())
      {
        q4pass = true;
      }
    }) */

  }

  

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  if(loading){
    return (
      
      <Grid style={{ width: "100%",display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }

  if(thankyou){
    return(
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
         flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Typography align='center' style={{ fontWeight: 700, fontSize: 36 }}>
          Thank You For Your Registration.
          </Typography>
          <Typography align='center' style={{ fontSize: 12, marginTop: 15 }}>
          An email with the details about your registration has been sent to the email address provided.
          </Typography>
          <Typography align='center' style={{ fontSize: 12, marginTop: 5 }}>
          Please keep it for your records.
          </Typography>

          <Grid style={{ width: "100%", maxWidth: 500, height: 2, borderBottom: '1px solid white', opacity: .5, marginTop: 20, marginBottom: 10  }}/>
          

          <Typography align='center' style={{ fontSize: 12, marginTop: 15 }}>
          We're dedicated to giving you the best experience possible. 
          </Typography>
          <Typography align='center' style={{ fontSize: 12, marginTop: 5 }}>
          If you have any questions, feel free to get in touch.
          </Typography>
          <Button variant="contained" style={{ marginTop: 20 }} href={`/${currentCountry}-extended-warranty-registration`}   disableElevation>
          Back
          </Button>
        </Grid>
      </Grid>

    )
  }

  return (
    <>
      <Helmet>
        <title> {`${currentCountryName} | Extended Warranty Registration`} </title>
      </Helmet>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to submit this form?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Grid style={{ width: "100%", display: "flex",
            flexDirection: "column" }}>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Full Name: ${fullname || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Your ID: ${clientId || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Mobile Number: ${mobileNum || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Email: ${email || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Home Address: ${homeAddress || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`City: ${city || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`IMEI No: ${deviceImei || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Serial No: ${serialNumber || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Purchase Date: ${date ? date.$d.toISOString().substring(0, 10) : ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`Attached File: ${file ? file.name : ""}`}
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={saveData} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, 
         flexDirection: "column", alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 30 }}>
          {`${currentCountryName} | Extended Warranty Registration`}
            </Typography>

            <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '30ch' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={submitHandler}
    >
      <Typography style={{ fontWeight: 700, fontSize: 18, marginTop: 30 }}>
      Personal Information
            </Typography>
      <Grid >
        <Grid container style={{ width: "100%"}} spacing={2}>

          <Grid item xs={12} md={6}>
            <TextField
            value={fullname}
            onChange={handleChangeFullname}
            style={{ margin: 0, marginTop: 15,width: "100%" }}
              required
              error={fullnameError}
              id="outlined-required"
              label="Full Name"
              type='text'
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              value={clientId}
              error={clientIdError}
              onChange={handleChangeClientId}
              style={{ margin: 0, marginTop: 15, width: "100%" }}
              id="outlined-required"
              label={alignment === "nic" ? "NIC Number" : "Passport Number"}
            />
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                style={{ marginTop: 10 }}
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="nic">NIC</ToggleButton>
                <ToggleButton value="passport">Passport</ToggleButton>
              </ToggleButtonGroup>
          </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                id="outlined-number"
                label="Mobile Number"
                type="number"
                value={mobileNum}
                error={mobileNumError}
                onChange={handleChangeMobileNum}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                required
                value={email}
                error={emailError}
                onChange={handleChangeEmail}
                type='email'
                id="outlined-required"
                label="Email Address"
              />
            </Grid>

          <Grid item xs={12}>
              <TextField
              value={homeAddress}
              onChange={handleChangeHomeAddress}
              style={{ margin: 0, marginTop: 15, width: "100%" }}
              id="outlined-required"
              label="Home Address"
              rows={4}
              multiline
            />
          </Grid>

            <Grid item xs={12} md={6} >
                <TextField
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                required
                id="outlined-helperText"
                label="City"
                value={city}
                error={cityError}
                onChange={handleChangeCity}
              />
            </Grid>

            <Grid item xs={12} md={6} >
                <TextField
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                required
                id="outlined-helperText"
                label="Reseller Name"
                value={resellerName}
                error={resellerNameError}
                onChange={handleChangeResellerName}
              />
            </Grid>

        </Grid>
        
        
        
      </Grid>
      <Typography style={{ fontWeight: 700, fontSize: 18, marginTop: 30 }}>
      Add Device Information
            </Typography>
      <Grid container style={{ width: "100%"}} spacing={2}>
        <Grid item xs={12} md={6} >
          <TextField
            style={{ margin: 0, marginTop: 15, width: "100%" }}
            required
              id="outlined-helperText"
              label="Device IMEI"
              value={deviceImei}
              type="number"
              error={(deviceImeiError || imeiRegistered)}
              onChange={handleChangeDeviceImei}
            />
            {imeiRegistered ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          This IMEI has been registed.
          </Typography> :null}
          {deviceImeiError ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          IMEI Not Found.
          </Typography> : null}
        </Grid>
       
        <Grid item xs={12} md={6} >
          <TextField
         style={{ margin: 0, marginTop: 15, width: "100%" }}
          required
              id="outlined-helperText"
              label="Serial Number"
              inputProps={{ style: { textTransform: "uppercase" } }}
              value={serialNumber}
              error={(serialNumberError || serialNoRegistered)}
              onChange={handleChangeSerialNumber}
            />
            {serialNoRegistered ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          This Serial Number has been registed.
          </Typography> : null}
          {serialNumberError ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          Serial Number Not Found.
          </Typography> : null }
        </Grid>

        <Grid item xs={12} md={6} style={{ paddingLeft: 7, paddingTop: 25 }}>
          <LocalizationProvider sx={{ width: "100%"}}  dateAdapter={AdapterDayjs}>
            <DatePicker 
              label="Purchase Date"
              value={date}
              onChange={(newDate) => setDate(newDate)}
              sx={{ width: "100%"}}  />
          </LocalizationProvider>
        </Grid>

        < Grid item xs={12} md={6} style={{ paddingLeft: 7, paddingTop: 25 }}>
            <MuiFileInput color='primary'
            required
            error={fileInputError}
            placeholder="Upload Invoice/Receipt*"
            getInputText={(value) => value ? value.name : 'Upload Invoice/Receipt*'}
            inputProps={{ accept: 'image/jpeg,image/png,application/pdf' }}
            value={file} onChange={handleOnChangeUploadfile} />
        </Grid>

      </Grid>

      <Grid style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: 30  }}>
      <FormGroup>
        <FormControlLabel required control={<Checkbox onChange={handleChangeAgreement} checked={agreement}  />} label={
          <Grid style={{ display: "flex", flexDirection: "column" }}>
            <Typography style={{ fontWeight: 700, fontSize: 14 }}>
              {`I agree to the (${termName})`}
            </Typography>
            <Link href="/terms-and-conditions" target='_blank'>
        <Typography style={{ fontWeight: 700, fontSize: 14 }}>
        Terms and Conditions *
            </Typography>

            {agreement ? null : <Typography style={{ fontWeight: 100, fontSize: 10, color: 'red' }}>
        This field is required
            </Typography> }

      </Link>
          </Grid>} />
        
      </FormGroup>
      
      
      </Grid>
      <Button disabled={phoneModel === null}  type="submit" variant="contained" style={{ marginTop: 30 }}  disableElevation>
            Submit Your Registration
          </Button>
    </Box>


            
        </Grid>

       
        
      </Grid>
    </>
  );
}
