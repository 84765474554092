import React, { useEffect }  from "react";
import { Paper, 
  Typography, 
  Grid, 
  Button, 
  TextField,
  Stepper,
  Step,
  StepLabel,
  Divider,
  CircularProgress,
  Backdrop
 } from '@mui/material';

// import DropzoneInvoice from '../../modules/components/DropzoneInvoice'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from "moment";
import { getAuth } from "firebase/auth";
import { MuiFileInput } from 'mui-file-input'
// import DropzonePreRepairImage from '../../modules/components/DropzonePreRepairImage'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import { getFirestore, doc, setDoc, serverTimestamp, Timestamp, getDocs, collection, query, where } from "firebase/firestore";
import { initializeApp } from "firebase/app";

import { UserAuth } from '../../context/AuthContext'

const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-54a3a.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const rtdb = getDatabase(app);

function Index(props) {
   const { user } = UserAuth()

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Submission', 'Summary', 'Completed'];
    
    const [product, setProduct] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [saveLoading, setSaveLoading] = React.useState(false);

    // step1
    const [invoice, setInvoice] = React.useState(null);
    const [isInvoice, setIsInvoice] = React.useState(false);

    // step2
    const [incidentDate, setIncidentDate] = React.useState(null);
    const [incidentLocation, setIncidentLocation] = React.useState('');
    const [incidentDetail, setIncidentDetail] = React.useState('');
    const [isIncidentDate, setIsIncidentDate] = React.useState(false);
    const [isIncidentLocation, setIsIncidentLocation] = React.useState(false);
    const [isIncidentDetail, setIsIncidentDetail] = React.useState(false);
    const [isPreRepairImage, setIsPreRepairImage] = React.useState(false);
    const [PreRepairImage, setPreRepairImage] = React.useState(null);

    // step3
    const [customerName, setCustomerName] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [customerEmail, setCustomerEmail] = React.useState('');
    const [isCustomerName, setIsCustomerName] = React.useState(false);
    const [isCustomerNumber, setIsCustomerNumber] = React.useState(false);
    const [isCustomerEmail, setIsCustomerEmail] = React.useState(false);

    const [userdata, setUserData] = React.useState(null);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    
    useEffect(() => {

      let _user = "";
      if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
        _user = JSON.parse(user.reloadUserInfo.customAttributes);
      }

      setUserData(_user)
      
      if(props.product)
      {
        setProduct(props.product)
        setLoading(false)
      }
      
  
    }, [props]);

    // step1
    const handleDropzoneInvoice = (files) => {
      setInvoice(files)
    } 

    const handleSubmitStep1 = () => {


      let checkingCount = 0;
      if(invoice && (invoice.type === "image/jpeg" || invoice.type === "image/png" || invoice.type === "application/pdf" )){
        setIsInvoice(false)

        checkingCount+= 1;;
      }
      else
      {
        setIsInvoice(true)
      }

      if(PreRepairImage && (PreRepairImage.type === "image/jpeg" || PreRepairImage.type === "image/png" || PreRepairImage.type === "application/pdf" )){
        setIsPreRepairImage(false)

        checkingCount+= 1;;
      }
      else
      {
        setIsPreRepairImage(true)
      }
      

      if(incidentDate){
        setIsIncidentDate(false)
        checkingCount+= 1;;
      }
      else
      {
        setIsIncidentDate(true)
      }

      if(incidentLocation.length > 1){
        setIsIncidentLocation(false)
        checkingCount+= 1;;
      }
      else
      {
        setIsIncidentLocation(true)
      }
  
      if(incidentDetail.length > 3){
        setIsIncidentDetail(false)
        checkingCount+= 1;;
      }
      else
      {
        setIsIncidentDetail(true)
      }

      if(customerName.length > 3){
        setIsCustomerName(false)
        checkingCount+= 1;;
      }
      else
      {
        setIsCustomerName(true)
      }
  
      if(customerNumber.length > 7){
        setIsCustomerNumber(false);
        checkingCount+= 1;;
      }
      else
      {
        setIsCustomerNumber(true)
      }
      

      if(checkingCount === 7){
        setActiveStep(1)
      }
      
    }

    // step2
    const handleIncidentDate = (event) => {
      setIncidentDate(event.target.value);
    }

    const handleIncidentLocation = (event) => {
      setIncidentLocation(event.target.value);
    }
  
    const handleIncidentDetail= (event) => {
      setIncidentDetail(event.target.value);
    }

    const handleDropzonePreRepairImage = (files) => {
      setPreRepairImage(files)
    } 

    // step3
    const handleCustomerName = (event) => {
      setCustomerName(event.target.value);
    }
  
    const handleCustomerNumber = (value) => {
      setCustomerNumber(value);
    }
  
    const handleCustomerEmail = (event) => {
      setCustomerEmail(event.target.value.trim());
    }

    const validateEmail = (email) => {
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regexp.test(email);
    }

    const getID =()=>{

      const auth = getAuth();
      const user = auth.currentUser;

      const storage = getStorage(app);
      const storageRef = ref(storage, props.claimoption === "sc" ? 
        `sc_claim_warrantycard/${Timestamp.fromDate(new Date()).toMillis()}_${invoice.name.replace(/\s/g,'')}`
      : `ew_claim_warrantycard/${Timestamp.fromDate(new Date()).toMillis()}_${invoice.name.replace(/\s/g,'')}`);
      

          // 'file' comes from the Blob or File API
          uploadBytes(storageRef, invoice).then((snapshot1) => {

            const storageprerepairimage = getStorage(app);
            const storageRefprerepairimage = ref(storageprerepairimage, 
              props.claimoption === "sc" ? 
              `sc_claim_pre_repair/${Timestamp.fromDate(new Date()).toMillis()}_${PreRepairImage.name.replace(/\s/g,'')}`
            : `ew_claim_pre_repair/${Timestamp.fromDate(new Date()).toMillis()}_${PreRepairImage.name.replace(/\s/g,'')}`);
  
            uploadBytes(storageRefprerepairimage, PreRepairImage).then((snapshot2) => {

              let sequenceID = 0;
  
              get(child(dbref(rtdb), props.claimoption === "sc" ? `sc-claim-sequence` : `ew-claim-sequence`)).then((snapshot) => {
                if (snapshot.exists()) {
                  sequenceID = snapshot.val().count + 1;
                  set(child(dbref(rtdb), props.claimoption === "sc" ? `sc-claim-sequence` : `ew-claim-sequence`), { count: sequenceID })
                
                } else {
                  set(child(dbref(rtdb), props.claimoption === "sc" ? `sc-claim-sequence` : `ew-claim-sequence`), { count: 1})
                  sequenceID = 1;
                }

                getDownloadURL(ref(storage, snapshot1.metadata.fullPath))
                .then((url1) => {
                  getDownloadURL(ref(storage, snapshot2.metadata.fullPath))
                  .then((url2) => {

                    const ID = props.claimoption === "sc" ? `IP-SC-${leftPad(sequenceID, 6)}` : `IP-EW-${leftPad(sequenceID, 6)}`;
                    product.claim_id = ID;
                    try
                    {
                      set(dbref(rtdb, props.claimoption === "sc" ? `sc_claimed/${ID}` : `ew_claimed/${ID}`), {
                        id: ID,
                        createdby: user.uid,
                        created: Timestamp.fromDate(new Date()).seconds,
                        updated: Timestamp.fromDate(new Date()).seconds,
                        imeino: product.imeino,
                        serialno: product.serialno,
                        purchase_date: product.purchase_date,
                        
                        incident_date: incidentDate,
                        incident_location: incidentLocation,
                        incident_detail: incidentDetail,
        
                        customer_name: customerName,
                        customer_phone: customerNumber,
                        customer_email: customerEmail,
                        status: "submit_for_approval",
        
                        city: product.city || null,
                        country: product.country || null,
                        phone_model: product.phone_model || null,
                        phone_color: product.phone_color || null,
                        phone_capacity: product.phone_capacity || null,
                        phone_partno: product.phone_partno || null,
        
                        purchase_invoice_fullPath: url1 || null,
                        purchase_invoice_contentType: snapshot1.metadata.contentType || null,

                        pre_repair_fullPath: url2 || null,
                        pre_repair_contentType: snapshot2.metadata.contentType || null,

                        servicecenter: userdata.servicecenter
                      }).then(()=>{
                        
                        if(props.resubmitId)
                        {
                          update(dbref(rtdb, props.claimoption === "sc" ?`sc_claimed/${props.resubmitId}`
                            : `ew_claimed/${props.resubmitId}`
                          ), {
                            resubmitted: true,
                            status: "rejected",
                          })
                        }

                        const emailID = `Claim-${Timestamp.fromDate(new Date()).toMillis()}`;
                        try
                        {
                          setDoc(doc(db, "mail", emailID), {
                            to: customerEmail !== null || customerEmail !== "" ? 
                            [customerEmail, user.email]:
                            [user.email],
                            message: {
                              subject: 'Gnext - Acknowledgement of Update Insurance',
                              html: `<!DOCTYPE html>
                              <html>
                              <head>
                              
                                <meta charset="utf-8">
                                <meta http-equiv="x-ua-compatible" content="ie=edge">
                                <title>Gnext - Acknowledgement of Update Insurance</title>
                                <meta name="viewport" content="width=device-width, initial-scale=1">
                                <style type="text/css">
                                /**
                                 * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
                                 */
                                @media screen {
                                  @font-face {
                                    font-family: 'Source Sans Pro';
                                    font-style: normal;
                                    font-weight: 400;
                                    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
                                  }
                                  @font-face {
                                    font-family: 'Source Sans Pro';
                                    font-style: normal;
                                    font-weight: 700;
                                    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
                                  }
                                }
                                /**
                                 * Avoid browser level font resizing.
                                 * 1. Windows Mobile
                                 * 2. iOS / OSX
                                 */
                                body,
                                table,
                                td,
                                a {
                                  -ms-text-size-adjust: 100%; /* 1 */
                                  -webkit-text-size-adjust: 100%; /* 2 */
                                }
                                /**
                                 * Remove extra space added to tables and cells in Outlook.
                                 */
                                table,
                                td {
                                  mso-table-rspace: 0pt;
                                  mso-table-lspace: 0pt;
                                }
                                /**
                                 * Better fluid images in Internet Explorer.
                                 */
                                img {
                                  -ms-interpolation-mode: bicubic;
                                }
                                /**
                                 * Remove blue links for iOS devices.
                                 */
                                a[x-apple-data-detectors] {
                                  font-family: inherit !important;
                                  font-size: inherit !important;
                                  font-weight: inherit !important;
                                  line-height: inherit !important;
                                  color: inherit !important;
                                  text-decoration: none !important;
                                }
                                /**
                                 * Fix centering issues in Android 4.4.
                                 */
                                div[style*="margin: 16px 0;"] {
                                  margin: 0 !important;
                                }
                                body {
                                  width: 100% !important;
                                  height: 100% !important;
                                  padding: 0 !important;
                                  margin: 0 !important;
                                }
                                /**
                                 * Collapse table borders to avoid space between cells.
                                 */
                                table {
                                  border-collapse: collapse !important;
                                }
                                a {
                                  color: #1a82e2;
                                }
                                img {
                                  height: auto;
                                  line-height: 100%;
                                  text-decoration: none;
                                  border: 0;
                                  outline: none;
                                }
                                </style>
                              
                              </head>
                              <body style="background-color: #e9ecef;">
                              
                                <!-- start preheader -->
                                <div class="preheader" style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
                                Thank you for using the Gnext insurance. We are processing your application.
                                </div>
                                <!-- end preheader -->
                              
                                <!-- start body -->
                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                              
                                  <!-- start logo -->
                                  <tr>
                                    <td align="center" bgcolor="#e9ecef">
                                      <!--[if (gte mso 9)|(IE)]>
                                      <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                                      <tr>
                                      <td align="center" valign="top" width="600">
                                      <![endif]-->
                                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                                        <tr>
                                          <td align="center" valign="top" style="padding: 36px 24px;">
                                            <a href="https://genxtservices.com" target="_blank" style="display: inline-block;">
                                              <img src="https://firebasestorage.googleapis.com/v0/b/genxt-9f006.appspot.com/o/logo%2FGenxt-logo.png?alt=media&token=26854a16-dec8-48c7-bfb7-bd509fd23e3b" alt="Logo" border="0" width="150" style="display: block; width: 150px; max-width: 150px; min-width: 48px;">
                                            </a>
                                          </td>
                                        </tr>
                                      </table>
                                      <!--[if (gte mso 9)|(IE)]>
                                      </td>
                                      </tr>
                                      </table>
                                      <![endif]-->
                                    </td>
                                  </tr>
                                  <!-- end logo -->
                              
                                  <!-- start copy block -->
                                  <tr>
                                    <td align="center" bgcolor="#e9ecef">
                                      <!--[if (gte mso 9)|(IE)]>
                                      <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                                      <tr>
                                      <td align="center" valign="top" width="600">
                                      <![endif]-->
                                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
          
                                      <!-- start copy -->
                                        <tr>
                                          <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                            <p style="margin: 0;">Dear ${customerName},</p>
                                          </td>
                                        </tr>
                                        <!-- end copy -->
                              
                                        <!-- start copy -->
                                        <tr>
                                          <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                            <p style="margin: 0;">Thank you for using the Gnext insurance. We are processing your application.</p>
                                          </td>
                                        </tr>
                                        <!-- end copy -->
                              
                                        <!-- start copy -->
                                        <tr>
                                          <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                            <p style="margin: 0;">Application reference no: ${product.claim_id}</p>
                                            <p style="margin: 0;">IMEI No: ${product.imeino}</p>
                                            <p style="margin: 0;">Serial No: ${product.serialno}</p>
                                            <p style="margin: 0;">Product Model: ${product.phone_model}</p>
                                            <p style="margin: 0;">Product Color: ${product.phone_color}</p>
                                            <p style="margin: 0;">Product Capacity: ${product.phone_capacity}</p>

                                            <p style="margin: 0;">Incident Date: ${moment(incidentDate).format("DD-MM-YYYY HH:mm")}</p>
                                            <p style="margin: 0;">Incident Location: ${incidentLocation}</p>
                                            <p style="margin: 0;">Incident Details: ${incidentDetail}</p>
                                            <p></p>
                                            <p style="margin: 0;">Please do not reply to this email.</p>
                                          </td>
                                        </tr>
                                        <!-- end copy -->
                              
                                        <!-- start copy -->
                                        <tr>
                                          <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                                            <p style="margin: 0;">Cheers,<br> Gnext </p>
                                          </td>
                                        </tr>
                                        <!-- end copy -->

                                        <!-- start copy -->
                                        <tr>
                                          <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                                            <p style="margin: 0;">Date: ${moment().format("DD-MM-YYYY HH:mm")}</p>
                                          </td>
                                        </tr>
                                        <!-- end copy -->
                              
                                      </table>
                                      <!--[if (gte mso 9)|(IE)]>
                                      </td>
                                      </tr>
                                      </table>
                                      <![endif]-->
                                    </td>
                                  </tr>
                                  <!-- end copy block -->
                              
                                </table>
                                <!-- end body -->
                              
                              </body>
                              </html>`,
                            }
                          }).then(()=>{
          
                            
          
                          })

                          setSaveLoading(false)
                            setActiveStep(4)
                      }
                      catch(e)
                      {
                        // console.log(e)
                      }
                      
                    
                    })
                    }
                    catch(e)
                    {
                      // console.log(e)
                    }

                  })

                })
        
                

              })
              

            })

           
            
          });
  


    }

    const leftPad = (number, targetLength) => {
      let output = `${number}`;
      while (output.length < targetLength) {
          output = `0${output}`;
      }
      return output;
    }

    // step 4
    const handleSubmitStep4 = () => {
      setSaveLoading(true)
      getID();

    }

    if(loading)
    {
      return (
        <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
          <CircularProgress 
            disableShrink
            color="primary" />
        </div>);
    }

    return(
      <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", padding: 20 }}>
        
        <Grid style={{ width: "100%" }}>
          <Stepper activeStep={activeStep} orientation={matches? "horizontal" : "vertical"} style={{ padding: 0}}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Divider style={{  opacity: .2, marginTop: 30 }}/>
        </Grid>

        <Grid container style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: 20, maxWidth: 600 }}>

            <Grid container spacing={2} style={{ display: activeStep === 0 ?  "flex": "none" }}>

                <Grid item xs={12} md={12}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    IMEI No
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={product.imeino ? product.imeino : "-"}/>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Serial No
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={product.serialno ? product.serialno : "-"}/>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Product Brand
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={"Apple"}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Product Model
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={product.phone_model ? product.phone_model : "-"}/>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Product Color
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={product.phone_color ? product.phone_color : "-"}/>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                    Product Capacity
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={product.phone_capacity ? product.phone_capacity : "-"}/>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                  Activation Start Date
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={props.claimoption === "sc" ? 
                  moment(product.purchase_date).format("DD-MM-YYYY") : moment(product.purchase_date).add(12, 'M').format("DD-MM-YYYY")}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                  Coverage End Date
                  </Typography>
                  <TextField fullWidth variant="outlined" disabled value={props.claimoption === "sc" ? 
                  moment(product.purchase_date).add(12, 'M').format("DD-MM-YYYY"): moment(product.purchase_date).add(24, 'M').format("DD-MM-YYYY")}/>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                  Purchase Invoice
                  </Typography>

                  <MuiFileInput color='primary'
                      required
                      style={{ width: "100%"}}
                      error={isInvoice}
                      placeholder="Upload Purchase Invoice*"
                      getInputText={(value) => value ? value.name : 'Upload Purchase Invoice*'}
                      inputProps={{ accept: 'image/jpeg,image/png,application/pdf' }}
                      value={invoice} onChange={handleDropzoneInvoice} />
                </Grid>

                <Grid item xs={12}  style={{ marginTop: 5, marginBottom: 5}}>
                    <TextField
                        id="datetime-local"
                        label="Incident Date / Time *"
                        type="datetime-local"
                        fullWidth
                        
                        error={isIncidentDate}
                        value={incidentDate}
                        onChange={handleIncidentDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth variant="outlined" label="Incident Location *" 
                      error={isIncidentLocation}
                      autoComplete='off'
                      onChange={handleIncidentLocation}
                      value={incidentLocation}/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField minRows={3} multiline fullWidth variant="outlined" label="Incident Detail *" 
                      error={isIncidentDetail}
                      autoComplete='off'
                      onChange={handleIncidentDetail}
                      value={incidentDetail} />

                  <Typography variant='body2' style={{ marginBottom: 5, marginTop: 20 }}>
                      Pre Repair Image
                    </Typography>
                    <MuiFileInput color='primary'
                      required
                      style={{ width: "100%"}}
                      error={isPreRepairImage}
                      placeholder="Upload Pre Repair Image*"
                      getInputText={(value) => value ? value.name : 'Upload Pre Repair Image*'}
                      inputProps={{ accept: 'image/jpeg,image/png,application/pdf' }}
                      value={PreRepairImage} onChange={handleDropzonePreRepairImage} />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5}}>
                    <Typography variant='body2' style={{ marginTop: 5, marginBottom: 5}}>
                      Customer Name *
                    </Typography>
                    <TextField fullWidth variant="outlined" 
                    autoComplete='off'
                      error={isCustomerName}
                      onChange={handleCustomerName}
                      value={customerName}/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneInput
                        dropdownStyle={{ color: "#1c1c1c" }}
                        inputStyle={{ width: "100%"}}
                        country={'sg'}
                        value={customerNumber}
                        onChange={handleCustomerNumber}
                      />
                      {isCustomerNumber ? 
                      <Typography color={"error"} variant='caption' >
                      {"Invalid Phone Number"}
                      </Typography> : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField fullWidth variant="outlined" label="Customer Email"  
                     autoComplete='off'
                      onChange={handleCustomerEmail}
                      value={customerEmail}/>
                  </Grid>

                <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> handleSubmitStep1()}
                  >
                Next
                  </Button>
                </Grid>

            </Grid>

            

            <Grid container spacing={2} style={{ display: activeStep === 1 ?  "flex": "none" }}>

              <Grid item xs={12}  style={{ marginBottom: 10 }}>
                <Typography color="primary" variant='body1' style={{ fontWeight: "bold" }}>
                  Product Details
                </Typography>

                <Grid container  style={{ marginTop: 20 }}>
                  <Grid item xs={6}>
                  <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      IMEI No
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Serial No
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Brand
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Model
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Color
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                      Product Capacity
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                    Activation Start Date
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                    Coverage End Date
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8 }}>
                    Attached Purchase Invoice
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography variant='body2'>
                      {product.imeino ? product.imeino : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.serialno ? product.serialno : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {"Apple"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.phone_model ? product.phone_model : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.phone_color ? product.phone_color : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.phone_capacity ? product.phone_capacity : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.purchase_date ? moment(product.purchase_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {product.purchase_date ? moment(product.purchase_date).add(12, 'M').format("DD-MM-YYYY") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                    {`${invoice ? invoice.name : ""}`}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography color="primary" variant='body1' style={{ fontWeight: "bold", marginTop: 20 }}>
                  Incident Details
                </Typography>

                <Grid container  style={{ marginTop: 10 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Incident Date/Time 
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Incident Location
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Incident Detail
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                    Attached Pre Repair Image
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {incidentDate ? moment(incidentDate).format("DD-MM-YYYY HH:mm") : "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {incidentLocation || "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {incidentDetail || "-"}
                    </Typography>
                    <Typography variant='body2'>
                    {`${PreRepairImage ? PreRepairImage.name : ""}`}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography color="primary" variant='body1' style={{ fontWeight: "bold", marginTop: 20 }}>
                  Customer Details
                </Typography>

                <Grid container  style={{ marginTop: 10 }}>
                  <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Customer Name
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Customer Contact No.
                    </Typography>
                    <Typography variant='body2' style={{ fontWeight: "bold", opacity: .8  }}>
                      Customer Email
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2'>
                      {customerName || "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {customerNumber || "-"}
                    </Typography>
                    <Typography variant='body2'>
                      {customerEmail || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                

              </Grid>

              <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    variant="contained"
                    disableElevation
                    onClick={()=> setActiveStep(0)}
                  >
                Back
                  </Button>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> handleSubmitStep4()}
                  >
                Submit
                  </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ display: activeStep === 4 ?  "flex": "none" }}>

              <Grid item xs={12}  style={{ marginBottom: 10, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Typography align="center" color="primary" variant='body1' style={{ fontWeight: "bold", fontSize: 24, display: "flex", justifyContent: "center" }}>
                  Thank you for your submission!
                </Typography>
                <Typography align="center" color="primary" variant='body1' style={{ fontSize: 14, display: "flex", justifyContent: "center" }}>
                We are processing your application as soon as possible.
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} style={{ width: "100%" , display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    style={{textTransform: 'none', marginLeft: 10   }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={()=> props.handleClose()}
                  >
                Back
                  </Button>
                </Grid>
            </Grid>

        </Grid>

        <Backdrop style={{ zIndex: 999 }} open={saveLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    )
}
  
export default Index;