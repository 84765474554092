// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Claim',
    path: '/dashboard/search_claim',
    icon: icon('ic_insurance'),
  }
];

export default navConfig;
